export const namespaced = true;

export const state = {
  call: null,
  loading: false,
  error: null,
};

export const mutations = {
  SET_CALL(state, call) {
    state.call = call;
  },

  SET_CALL_NOTE(state, note) {
    state.call.notes = note;
  },

  SET_LOADING(state, loading) {
    state.loading = loading;
  },

  SET_ERROR(state, error) {
    state.error = error;
  },
};

export const actions = {
  setCall({ commit }, { call }) {
    commit("SET_CALL", call);
  },

  async saveNote({ dispatch, commit, state }, { note}) {
    commit("SET_LOADING", true);
    commit("SET_ERROR", null);

    try {
      const request = {
        request: "calls",
        action: "edit-notes",
        uniqueId: state.call.uniqueId,
        notes: note,
      };

      dispatch("sendMessage", JSON.stringify(request), { root: true });
    } catch (error) {
      commit(
        "SET_ERROR",
        error?.response?.data?.message ?? "Something went wrong"
      );
    } finally {
      commit("SET_LOADING", false);
    }
  },
  async wsListeners({ rootState, commit }) {
    rootState.ws.addEventListener("message", (msg) => {
      const message = JSON.parse(msg.data);
      switch (message.action) {
        case "call-edit-notes": {
          commit("SET_CALL_NOTE", message.note.notes);
          break;
        }
      }
    });
  },
};

export const getters = {
  call: (state) => state.call,
  loading: (state) => state.loading,
  error: (state) => state.error,
};
