import JsSIP from "jssip";
import { markRaw } from "vue";

export const namespaced = true;

export const state = {
  socket: null,
  phone: null,
  session: null,
  remoteAudio: null,

  displayMute: false,
  displayHangup: false,
  displayMakeCall: true,

  speakers: [],
  speakerMuted: false,
  selectedSpeaker: null,
  speakerVolume: 100,

  microphones: [],
  microphoneMuted: false,
  selectedMicrophone: null,
  micVolume: 100,

  selectedRoom: null,
  generatedCallers: {},

  loading: false,
  connected: false,

  callAnswered: false,
};

export const mutations = {
  CLEAR_DEVICES(state) {
    state.speakers = [];
    state.microphones = [];
  },

  ADD_SPEAKER(state, speaker) {
    state.speakers.push(speaker);
  },

  ADD_MICROPHONE(state, microphone) {
    state.microphones.push(microphone);
  },
  SET_INITIAL_DEVICES(state) {
    state.selectedSpeaker = state.speakers[0];
    state.selectedMicrophone = state.microphones[0];
  },
  SET_SESSION(state, session) {
    state.session = session;
  },
  TERMINATE_SESSION(state) {
    state.session.terminate();
  },
  CLEAR_SESSION(state) {
    state.session = null;
  },
  SET_SOCKET(state, socket) {
    state.socket = socket;
  },
  SET_REMOTE_AUDIO(state, remoteAudio) {
    state.remoteAudio = remoteAudio;
  },
  SET_PHONE(state, phone) {
    state.phone = phone;
  },
  SHOW_MUTE(state) {
    state.displayMute = true;
  },
  HIDE_MUTE(state) {
    state.displayMute = false;
  },
  SHOW_HANGUP(state) {
    state.displayHangup = true;
  },
  HIDE_HANGUP(state) {
    state.displayHangup = false;
  },
  SHOW_MAKE_CALL(state) {
    state.displayMakeCall = true;
  },
  HIDE_MAKE_CALL(state) {
    state.displayMakeCall = false;
  },
  MUTE_SESSION(state) {
    state.session.mute({ audio: true });
  },
  UNMUTE_SESSION(state) {
    state.session.unmute({ audio: true });
  },
  SET_CONNECTED(state, connected) {
    state.connected = connected;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_CALLER(state, caller) {
    state.generatedCallers[caller.number] = caller;
  },
  SET_SPEAKER_MUTED(state, value) {
    state.speakerMuted = value;
  },
  SET_MICROPHONE_MUTED(state, value) {
    state.microphoneMuted = value;
  },
  SET_SPEAKER_VOLUME(state, value) {
    state.speakerVolume = value;
  },
  SET_MICROPHONE_VOLUME(state, value) {
    state.micVolume = value;
  },
  SET_SELECTED_ROOM(state, room) {
    state.selectedRoom = room;
  },

  SET_SELECTED_SPEAKER(state, speaker) {
    state.selectedSpeaker = speaker;
  },

  SET_SELECTED_MICROPHONE(state, microphone) {
    state.selectedMicrophone = microphone;
  },

  SET_CALL_ANSWERED(state, value) {
    state.callAnswered = value;
  },
};

export const actions = {
  setCaller({ commit }, caller) {
    commit("SET_CALLER", caller);
    commit("SET_LOADING", false);
  },
  setRemoteAudio({ commit }, remoteAudio) {
    commit("SET_REMOTE_AUDIO", remoteAudio);
  },
  async initWebRtc({ commit }, webrtcData) {
    const socket = new JsSIP.WebSocketInterface(webrtcData.server);
    commit("SET_SOCKET", socket);
  },
  async initWebRtcListeners({ state, commit, dispatch }, webrtcData) {
    JsSIP.debug.enable("JsSIP:*");

    const configuration = {
      sockets: [state.socket],
      uri: `sip:${webrtcData.username}@${webrtcData.domain}`, //"sip:ownage1@dashv2.own.ag",
      username: webrtcData.username,
      password: webrtcData.password,
      register: true,
      session_timers: false,
    };
    const phone = new JsSIP.UA(configuration);

    phone.on("disconnected", (event) => {
      const reason =
        event.code === 1006
          ? `Something went wrong (${event.code})`
          : event.reason;
      dispatch(
        "addError",
        { error: `(WebRTC) - ${reason}`, errorType: "webrtc" },
        { root: true }
      );
    });

    phone.on("connected", (event) => {
      console.log("PHONE_CONNECTED", event);
      dispatch("removeErrors", { errorType: "webrtc" }, { root: true });
    });

    phone.on("registrationFailed", (event) => {
      console.log("PHONE_REGISTRATION_FAILED", event);
      dispatch(
        "addError",
        { error: `(WebRTC) - Registration Failed - ${event.cause}`, errorType: "webrtc" },
        { root: true }
      );
    });

    phone.on("registered", (event) => {
      console.log("PHONE_REGISTERED", event);
      dispatch("removeErrors", { errorType: "webrtc" }, { root: true });
    });

    phone.on("newRTCSession", (event) => {
      const session = event.session;
      console.log("session.direction", session.direction);

      if (state.session) {
        commit("TERMINATE_SESSION");
      }

      commit("SET_SESSION", session);

      state.session.on("ended", (event) => {
        console.log("SESSION_ENDED", event);
        commit("CLEAR_SESSION");
        commit("SET_CONNECTED", false);
        commit("SET_CALL_ANSWERED", false);
      });

      state.session.on("failed", (event) => {
        console.log("SESSION_FAILED", event);
        commit("CLEAR_SESSION");
        commit("SET_CALL_ANSWERED", false);
      });

      state.session.on("accepted", () => {
        console.log("SESSION_ACCEPTED");
        commit("SET_CONNECTED", true);
      });

      let iceCandidateTimeout = null;
      state.session.on("icecandidate", (candidate) => {
        console.log("GETTING_CANDIDATE" + candidate.candidate.candidate);
        if (iceCandidateTimeout != null) {
          clearTimeout(iceCandidateTimeout);
        }
        // 2 seconds timeout after the last icecandidate received!
        iceCandidateTimeout = setTimeout(candidate.ready, 2000);
      });

      if (state.session.direction === "incoming") {
        state.session.on("peerconnection", (e) => {
          const peerConnection = e.peerconnection;
          peerConnection.addEventListener("addstream", (e) => {
            console.log("incoming.ADD_STREAM", e);
            state.remoteAudio.srcObject = e.stream;
            state.remoteAudio.volume = state.speakerVolume / 100;
            state.remoteAudio.muted = state.speakerMuted;
            state.remoteAudio.play();
          });

          const remoteStream = new MediaStream();
          peerConnection.getReceivers().forEach(function (receiver) {
            remoteStream.addTrack(receiver.track);
          });
        });

        this.answerCall(session);
      } else {
        console.log("REMOTE_AUDIO", state.remoteAudio);
        state.remoteAudio.autoplay = true;
        state.remoteAudio.crossOrigin = "anonymous";

        console.log("Session.Connection", state.session.connection);
        state.session.connection.addEventListener("addstream", (e) => {
          console.log("outgoing.ADD_STREAM", e);
          state.remoteAudio.srcObject = e.stream;
          state.remoteAudio.volume = state.speakerVolume / 100;
          state.remoteAudio.muted = state.speakerMuted;
        });
      }
    });
    phone.start();
    commit("SET_PHONE", markRaw(phone));
  },
  async makeCall({ state }, { fromName, destination }) {
    let callOptions = {
      mediaConstraints: {
        audio: true,
        video: false,
      },
      pcConfig: {
        rtcpMuxPolicy: "negotiate",
        iceServers: [
          {
            urls: ["stun:stun.l.google.com:19302"],
          },
        ],
      },
    };

    console.log("MAKE_CALL", destination, callOptions);
    callOptions.fromDisplayName = fromName;
    state.phone.call(destination, callOptions);
  },
  answerCall({ commit }, session) {
    console.log("ANSWERING_CALL", session);
    session.answer({
      mediaConstraints: {
        audio: true,
        video: false,
      },
      pcConfig: {
        rtcpMuxPolicy: "negotiate",
        iceServers: [
          {
            urls: ["stun:stun.l.google.com:19302"],
          },
        ],
      },
    });
    commit("SET_CALL_ANSWERED", true);
  },
  hangup({ commit }) {
    commit("TERMINATE_SESSION");
  },
  reject({ commit }) {
    commit("TERMINATE_SESSION");
  },
  toggleMute({ commit, state }) {
    if (state.session.isMuted().audio) {
      commit("UNMUTE_SESSION");
    } else {
      commit("MUTE_SESSION");
    }
  },
  setConnected({ commit }) {
    commit("SET_CONNECTED", true);
  },
  setSpeakerMuted({ commit }, value) {
    commit("SET_SPEAKER_MUTED", value);
  },
  setCallAnswered({ commit }, value) {
    commit("SET_CALL_ANSWERED", value);
  },
  setMicrophoneMuted({ commit }, value) {
    commit("SET_MICROPHONE_MUTED", value);
  },
  setSpeakerVolume({ commit }, value) {
    commit("SET_SPEAKER_VOLUME", value);
  },
  setMicrophoneVolume({ commit }, value) {
    commit("SET_MICROPHONE_VOLUME", value);
  },
  async loadDevices({ commit }) {
    const devices = await navigator.mediaDevices.enumerateDevices();
    commit("CLEAR_DEVICES");
    devices.forEach((device) => {
      if (device.kind === "audioinput") {
        commit("ADD_MICROPHONE", device);
      } else if (device.kind === "audiooutput") {
        commit("ADD_SPEAKER", device);
      }
    });
    commit("SET_INITIAL_DEVICES");
  },
  selectRoom({ commit }, { room }) {
    commit("SET_SELECTED_ROOM", room);
  },

  selectSpeaker({ commit }, { speaker }) {
    commit("SET_SELECTED_SPEAKER", speaker);
  },

  selectMicrophone({ commit }, { microphone }) {
    commit("SET_SELECTED_MICROPHONE", microphone);
  },
  generate({ dispatch, commit }, { number, closestMatch, searchRealNumbers }) {
    commit("SET_LOADING", true);
    const request = {
      request: "calls",
      action: "generate-caller-id",
      number: number,
      closestMatch: closestMatch,
      searchRealNumbers: searchRealNumbers,
    };
    dispatch("sendMessage", JSON.stringify(request), { root: true });
  },
  async wsListeners({ dispatch, rootState }) {
    rootState.ws.addEventListener("message", (msg) => {
      const message = JSON.parse(msg.data);
      switch (message.action) {
        case "generate-caller-id": {
          console.log("GENERATE_CALLER_ID", message);
          dispatch("setCaller", message.caller);
          break;
        }
      }
    });
  },
};

export const getters = {
  displayHangup: (state) => {
    return state.displayHangup;
  },
  displayMute: (state) => {
    return state.displayMute;
  },
  displayMakeCall: (state) => {
    return state.displayMakeCall;
  },
  isSessionMuted: (state) => {
    return state.session.isMuted().audio;
  },
  speakers: (state) => state.speakers,
  microphones: (state) => state.microphones,

  micVolume: (state) => state.micVolume,
  speakerVolume: (state) => state.speakerVolume,

  selectedSpeaker: (state) => state.selectedSpeaker,
  selectedMicrophone: (state) => state.selectedMicrophone,

  speakerMuted: (state) => state.speakerMuted,
  microphoneMuted: (state) => state.microphoneMuted,

  selectedRoom: (state) => state.selectedRoom,
  generatedCallers: (state) => state.generatedCallers,
  loading: (state) => state.loading,
  connected: (state) => state.connected,

  session: (state) => state.session,
  phone: (state) => state.phone,
};
