<template>
  <div class="inset-y-0 left-0 static flex-shrink-0">
    <a
      href="/"
      class="flex items-center justify-center h-16 bg-gray-800 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600 w-20"
    >
      <img class="h-8 w-auto" src="@/assets/logo.png" alt="Workflow" />
    </a>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
