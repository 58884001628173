// import greetingsApi from "@/network/greetings.js";

export const namespaced = true;

export const state = {
  greetings: [],
  page: 1,
  totals: 0,
  loading: false,
  error: null,
};

export const mutations = {
  SET_GREETINGS(state, greetings) {
    state.greetings = greetings;
  },

  ADD_GREETINGS(state, greeting) {
    state.greetings.unshift(greeting);
  },

  REMOVE_GREETING(state, id) {
    state.greetings = state.greetings.filter((greeting) => greeting.id !== id);
  },

  UPDATE_GREETING(state, greeting) {
    const greetingIndex = state.greetings.findIndex(
      (greetingItem) => greetingItem.greetingId === greeting.greetingId
    );
    if (state.greetings[greetingIndex]) {
      state.greetings[greetingIndex] = greeting;
    }
  },

  SET_PAGE(state, value) {
    state.page = value;
  },

  SET_TOTALS(state, value) {
    state.totals = value;
  },

  SET_LOADING(state, loading) {
    state.loading = loading;
  },

  SET_ERROR(state, error) {
    state.error = error;
  },
};

export const actions = {
  async listGreetings({ commit, dispatch }, payload) {
    commit("SET_LOADING", true);
    const defaultLimit = 100;
    const greetingLimit = payload?.limit ? payload?.limit : defaultLimit;

    const request = {
      request: "greetings",
      action: "list",
      limit: greetingLimit,
    };
    dispatch("sendMessage", JSON.stringify(request), { root: true });
  },

  addGreeting({ commit }, { greeting }) {
    commit("ADD_GREETINGS", greeting);
  },

  updateGreeting({ commit }, { greeting }) {
    commit("UPDATE_GREETING", greeting);
  },

  removeGreeting({ commit }, { id }) {
    commit("REMOVE_GREETING", id);
  },

  updatePage({ commit }, { page }) {
    commit("SET_PAGE", page);
  },
  async wsListeners({ commit, rootState }) {
    rootState.ws.addEventListener("message", (msg) => {
      const message = JSON.parse(msg.data);
      switch (message.action) {
        case "list-greetings": {
          commit("SET_GREETINGS", message.greetings);
          commit("SET_TOTALS", message.greetings.length);
          commit("SET_LOADING", false);
          break;
        }
      }
    });
  },
};

export const getters = {
  greetings: (state) => state.greetings,
  page: (state) => state.page,
  totals: (state) => state.totals,
  loading: (state) => state.loading,
  error: (state) => state.error,
};
