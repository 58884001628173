import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./index.css";

import VueProgressBar from "@aacassandra/vue3-progressbar";
import VCalendar from 'v-calendar';
import 'v-calendar/dist/style.css';


const progressBarOptions = {
  color: "#F59E0B",
  thickness: "5px",
  transition: {
    speed: "0.2s",
    opacity: "0.6s",
    termination: 300,
  },
  autoRevert: true,
  location: "top",
  inverse: false,
};

const app = createApp(App);
app.use(store);
app.use(router);
app.use(VueProgressBar, progressBarOptions);
app.use(VCalendar, {});

app.mount("#app");
