export const namespaced = true;

export const state = {
  contacts: [],
  page: 1,
  totals: 0,
  loading: false,
  error: null,
};

export const mutations = {
  SET_CONTACTS(state, contacts) {
    state.contacts = contacts;
  },

  ADD_CONTACT(state, contact) {
    state.contacts.unshift(contact);
  },

  REMOVE_CONTACT(state, number) {
    state.contacts = state.contacts.filter((item) => item.number !== number);
  },

  UPDATE_CONTACT(state, contact) {
    const index = state.contacts.findIndex(
      (item) => item.number === contact.number
    );
    if (state.contacts[index]) {
      state.contacts[index] = contact;
    }
  },

  SET_PAGE(state, value) {
    state.page = value;
  },

  SET_TOTALS(state, value) {
    state.totals = value;
  },

  UPDATE_TOTALS(state) {
    state.totals = state.contacts.length;
  },

  SET_LOADING(state, loading) {
    state.loading = loading;
  },

  SET_ERROR(state, error) {
    state.error = error;
  },
};

export const actions = {
  async listContacts({ commit, dispatch }) {
    commit("SET_LOADING", true);
    const request = {
      request: "contacts",
      action: "list",
    };
    dispatch("sendMessage", JSON.stringify(request), { root: true });
  },

  addContact({ commit, dispatch }, { contact }) {
    commit("SET_LOADING", true);

    const request = {
      request: "contacts",
      action: "create",
      number: contact.number,
      name: contact.name,
      notes: contact.notes,
    };
    dispatch("sendMessage", JSON.stringify(request), { root: true });
  },

  updateContact({ commit, dispatch }, { contact }) {
    commit("SET_LOADING", true);

    const request = {
      request: "contacts",
      action: "update",
      number: contact.number,
      name: contact.name,
      notes: contact.notes,
    };
    dispatch("sendMessage", JSON.stringify(request), { root: true });
  },

  removeContact({ commit, dispatch }, { number }) {
    commit("SET_LOADING", true);

    const request = {
      request: "contacts",
      action: "remove",
      number: number,
    };
    dispatch("sendMessage", JSON.stringify(request), { root: true });
  },

  updatePage({ commit }, { page }) {
    commit("SET_PAGE", page);
  },
  async wsListeners({ commit, rootState }) {
    rootState.ws.addEventListener("message", (msg) => {
      const message = JSON.parse(msg.data);
      switch (message.action) {
        case "list-contacts": {
          commit("SET_CONTACTS", message.contacts);
          commit("SET_TOTALS", message.contacts.length);
          commit("SET_LOADING", false);
          break;
        }
        case "create-contact": {
          commit("ADD_CONTACT", message.contact);
          commit("UPDATE_TOTALS");
          commit("SET_LOADING", false);
          break;
        }
        case "update-contact": {
          commit("UPDATE_CONTACT", message.contact);
          commit("UPDATE_TOTALS");
          commit("SET_LOADING", false);
          break;
        }
        case "remove-contact": {
          commit("REMOVE_CONTACT", message.contact?.number);
          commit("UPDATE_TOTALS");
          commit("SET_LOADING", false);
          break;
        }
      }
    });
  },
};

export const getters = {
  contacts: (state) => state.contacts,

  contactList: (state) => state.contacts.reduce((acc, contact) => {
    acc[contact.number] = contact;
    return acc;
  }, {}),

  target_contacts: (state) => {
    const PER_PAGE = 10;
    const lowerLimit = (state.page - 1) * PER_PAGE;
    const upperLimit =
      lowerLimit + PER_PAGE > state.contacts.length
        ? state.contacts.length
        : lowerLimit + PER_PAGE;
    return state.contacts.slice(lowerLimit, upperLimit);
  },

  page: (state) => state.page,
  totals: (state) => state.totals,
  loading: (state) => state.loading,
  error: (state) => state.error,
};
