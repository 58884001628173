<template>
  <div class="min-h-0 flex-1 flex overflow-hidden">
    <app-sidebar></app-sidebar>
    <main-area :title="title"></main-area>
  </div>
</template>

<script>
import AppSidebar from "./AppSidebar.vue";
import MainArea from "./MainArea.vue";
export default {
  components: {
    AppSidebar,
    MainArea,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped></style>
