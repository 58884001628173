import { createStore } from "vuex";

import * as auth from "@/store/auth";
import * as queues from "@/modules/queues/store.js";
import * as voicemail from "@/modules/voicemail/store.js";
import * as call_notes from "@/modules/call_notes/store.js";
import * as session_notes from "@/modules/session_notes/store.js";
import * as inbounds from "@/modules/inbounds/store.js";
import * as contacts from "@/modules/contacts/store.js";
import * as greetings from "@/modules/greetings/store.js";
import * as users from "@/modules/users/store.js";
import * as rooms from "@/modules/rooms/store.js";
import * as webrtc from "@/modules/webrtc/store.js";
import * as activeCalls from "@/modules/active_calls/store.js";
import * as recordings from "@/modules/recordings/store.js";
import * as recentCalls from "@/modules/recent_calls/store.js";
import * as missedCalls from "@/modules/missed_calls/store.js";
import * as incomingCalls from "@/modules/incoming_calls/store.js";
import * as smsStore from "@/modules/sms/store.js";
import * as prankStore from "@/modules/prank_requests/store.js";
import * as commonStore from "@/store/common.js";

export default createStore({
  state: () => ({
    route: null,
    ws: null,
    messages: [],
    errors: [],
    reverseLogin: false,
  }),
  modules: {
    auth,
    queues,
    voicemail,
    call_notes,
    session_notes,
    inbounds,
    greetings,
    users,
    rooms,
    webrtc,
    activeCalls,
    recordings,
    recentCalls,
    missedCalls,
    incomingCalls,
    smsStore,
    contacts,
    prankStore,
    commonStore
  },
  mutations: {
    SET_WEBSOCKET(state, ws) {
      state.ws = ws;
    },
    SET_ROUTE(state, routeName) {
      state.route = routeName;
    },
    ADD_ERROR(state, { error, errorType }) {
      if (state.errors.length >= 3) state.errors.splice(-1, 1);

      state.errors.unshift({
        id: new Date().getTime(),
        message: error,
        errorType: errorType ?? "general",
      });
    },
    REMOVE_ERRORS(state, errorType) {
      const errors = state.errors.filter(
        (errorItem) => errorItem.errorType !== errorType
      );
      state.errors = errors;
    },
    CLEAR_ERROR(state) {
      state.errors = [];
    },
    SET_REVERSE_LOGIN(state, value) {
      state.reverseLogin = value;
    },
  },
  actions: {
    async getTimezones() {
      return Intl.supportedValuesOf("timeZone");
    },
    async getCurrentTimezone() {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    },
    async initWs({ commit, dispatch, state }, token) {
      // console.log("initWs", token);

      if (token == null) {
        dispatch(
          "addError",
          {
            error: `(BACKEND) - Unable to connect to the BACKEND, Token is missing`,
            errorType: "websocket",
          },
          { root: true }
        );
        return;
      }
      const WSBACKEND_URL =
        process.env.VUE_APP_WSBACKEND_URL ?? "https://localhost/ms";
      const ws = new WebSocket(`${WSBACKEND_URL}?token=${token}`);
      commit("SET_WEBSOCKET", ws);

      dispatch("rooms/wsListeners");
      dispatch("recordings/wsListeners");
      dispatch("recentCalls/wsListeners");
      dispatch("missedCalls/wsListeners");
      dispatch("activeCalls/wsListeners");
      dispatch("incomingCalls/wsListeners");
      dispatch("inbounds/wsListeners");
      dispatch("voicemail/wsListeners");
      dispatch("greetings/wsListeners");
      dispatch("queues/wsListeners");
      dispatch("webrtc/wsListeners");
      dispatch("smsStore/wsListeners");
      dispatch("contacts/wsListeners");

      ws.addEventListener("open", () => {
        // console.log("WEBSOCKET_OPEN", event);
        dispatch("removeErrors", { errorType: "websocket" }, { root: true });

        dispatch("rooms/listRooms");
        dispatch("contacts/listContacts");
        dispatch("recordings/listRecordings", { limit: 1000 });
        dispatch("recordings/listSessions", { limit: 1000 });
        dispatch("recentCalls/listRecentCalls", { limit: 1000000 });
        dispatch("missedCalls/listMissedCalls", { limit: 1000000 });
        dispatch("queues/listQueues", { limit: 1000 });

        while (state.messages.length > 0) {
          ws.send(state.messages.pop());
        }
      });

      ws.addEventListener("error", (event) => {
        console.log("WEBSOCKET_ERROR", event);
        const reason =
          event.target.readyState === 3
            ? "The connection is closed or couldn't be opened."
            : "Something went wrong...";
        dispatch(
          "addError",
          { error: `(BACKEND) - ${reason}`, errorType: "websocket" },
          { root: true }
        );
        ws.close();
      });

      ws.addEventListener("close", (event) => {
        console.log(
          "WEBSOCKET_CLOSE",
          "Reconnect will be attempted in 1 second.",
          event.reason
        );

        ((token) => {
          setTimeout(() => {
            dispatch("initWs", token);
          }, 1000);
        })(token);
      });
    },
    async getCall({ dispatch }, payload) {
      const request = {
        request: "calls",
        action: "list-call",
        uniqueId: payload?.uniqueId ?? "",
      };

      dispatch("sendMessage", JSON.stringify(request), { root: true });
    },
    async sendMessage({ state }, message) {
      if (state.ws.readyState !== 1) {
        state.messages.push(message);
      } else {
        state.ws.send(message);
      }
    },
    addError({ commit }, { error, errorType }) {
      commit("ADD_ERROR", { error, errorType });
    },
    removeErrors({ commit }, { errorType = "general" }) {
      commit("REMOVE_ERRORS", errorType);
    },
    clearError({ commit }) {
      commit("CLEAR_ERROR");
    },
    setReverseLogin({ commit }, value) {
      commit("SET_REVERSE_LOGIN", value);
    },
  },
  getters: {
    errors: (state) => state.errors,
    reverseLogin: (state) => state.reverseLogin,
  },
});
