export const namespaced = true;

export const state = {
  rooms: [
    {
      answered: false,
      conference_name: "Main",
      conference_uuid: "4f792853-cb67-4801-9869-cf7b615a55c1",
      recording: false,
      hosts: [],
      member_count: 0,
      members: [],
    },
    {
      answered: false,
      conference_name: "Holding",
      conference_uuid: "4f792864-cb67-4801-0970-cf7b615a66d2",
      recording: false,
      hosts: [],
      member_count: 0,
      members: [],
    },
  ],
  loading: false,
  error: null,
  shouldRecall: false,
  shouldCallFromNumber: false,

  target_recall: null,
  target_number: null,

  ws: null,
  messages: [],
  target_member: null,
};

export const mutations = {
  ADD_HOST(state, { roomId, memberId }) {
    const roomIndex = state.rooms.findIndex(
      (roomItem) => roomItem.conference_name === roomId
    );

    if (roomIndex === -1) return;
    if (!state.rooms[roomIndex].hosts.includes(memberId)) {
      state.rooms[roomIndex].hosts.push(memberId);
    }
  },
  REMOVE_HOST(state, { roomId, memberId }) {
    const roomIndex = state.rooms.findIndex(
      (roomItem) => roomItem.conference_name === roomId
    );

    if (roomIndex === -1) return;

    const hostIndex = state.rooms[roomIndex].hosts.findIndex(
      (host) => host === memberId
    );

    if (hostIndex > -1) {
      state.rooms[roomIndex].hosts.splice(hostIndex, 1);
    }
  },
  SET_ROOMS(state, rooms) {
    state.rooms = rooms;
  },
  ADD_ROOM(state, room) {
    const roomIndex = state.rooms.findIndex(
      (roomItem) => roomItem.conference_name === room.conference_name
    );

    if (roomIndex !== -1) {
      state.rooms[roomIndex] = room;
    } else {
      state.rooms.push(room);
    }
  },
  REMOVE_ROOM(state, roomId) {
    const defaultRoomNames = ["Main", "Holding"];
    if (!defaultRoomNames.includes(roomId)) {
      const rooms = state.rooms.filter(
        (room) => room.conference_name !== roomId
      );
      state.rooms = rooms;
    }
  },
  ADD_MEMBER(state, { member, roomId }) {
    const roomIndex = state.rooms.findIndex(
      (room) => room.conference_name === roomId
    );
    if (roomIndex !== -1) {
      state.rooms[roomIndex].members.push(member);
      state.rooms[roomIndex].member_count =
        state.rooms[roomIndex].members.length;
    }
  },
  REMOVE_MEMBER(state, { memberId, roomId }) {
    const roomIndex = state.rooms.findIndex(
      (room) => room.conference_name === roomId
    );

    if (roomIndex !== -1) {
      const members = state.rooms[roomIndex].members.filter(
        (member) => parseInt(member.id) !== parseInt(memberId)
      );
      state.rooms[roomIndex].members = members;
      state.rooms[roomIndex].member_count = members.length;
    }
  },
  SET_MEMBER_FLAG(state, { memberId, roomId, flagName, flagValue }) {
    const roomIndex = state.rooms.findIndex(
      (room) => room.conference_name === roomId
    );

    if (roomIndex !== -1) {
      const memberIndex = state.rooms[roomIndex].members.findIndex(
        (member) => parseInt(member.id) === parseInt(memberId)
      );
      if (memberIndex !== -1) {
        state.rooms[roomIndex].members[memberIndex].flags[flagName] = flagValue;
      }
    }
  },
  ADD_MESSAGE(state, message) {
    state.messages.push(message);
  },
  START_ROOM_RECORDING(state, { roomId, recordingId }) {
    console.log("START_ROOM_RECORDING", roomId, recordingId);
    const roomIndex = state.rooms.findIndex(
      (room) => room.conference_name === roomId
    );
    if (roomIndex !== -1) {
      state.rooms[roomIndex].recording = true;
      state.rooms[roomIndex].recordingId = recordingId;
    }
  },
  STOP_ROOM_RECORDING(state, roomId) {
    console.log("START_ROOM_RECORDING", roomId);
    const roomIndex = state.rooms.findIndex(
      (room) => room.conference_name === roomId
    );
    if (roomIndex !== -1) {
      state.rooms[roomIndex].recording = false;
    }
  },
  SET_TARGET_MEMBER(state, memberId) {
    state.target_member = memberId;
  },
  CLEAR_TARGET_MEMBER(state) {
    state.target_member = null;
  },
  SET_SHOULD_RECALL(state, value) {
    state.shouldRecall = value;
  },
  SET_TARGET_RECALL(state, call) {
    state.target_recall = call;
  },
  SET_TARGET_NUMBER(state, number) {
    state.target_number = number;
  },
  SET_SHOULD_CALL_FROM_NUMBER(state, value) {
      state.shouldCallFromNumber = value;
  },
};

export const actions = {
  markAsHost({ dispatch }, { memberId, roomId }) {
    const request = {
      request: "mark",
      roomId: roomId,
      memberId: memberId,
    };

    // const roomIndex = state.rooms.findIndex(
    //   (roomItem) => roomItem.conference_name === roomId
    // );

    // if (roomIndex === -1) return;

    // if (state.rooms[roomIndex].hosts.length === 0)
    //   dispatch("startRecording", { roomId });
    dispatch("sendMessage", JSON.stringify(request), { root: true });
  },
  unmarkHost({ dispatch }, { memberId, roomId }) {
    const request = {
      request: "unmark",
      roomId: roomId,
      memberId: memberId,
    };
    dispatch("sendMessage", JSON.stringify(request), { root: true });
  },
  listRooms({ dispatch }) {
    const request = {
      request: "rooms",
    };
    dispatch("sendMessage", JSON.stringify(request), { root: true });
  },
  async placeCall({ dispatch }, { roomId, calledNumber, callerIdNumber }) {
    const request = {
      request: "originate",
      roomId: roomId,
      calledNumber: calledNumber,
      callerIdNumber: callerIdNumber,
    };
    dispatch("sendMessage", JSON.stringify(request), { root: true });
  },
  muteMember({ dispatch, commit }, { memberId, roomId }) {
    console.log("muteMember", memberId, roomId);
    commit("SET_TARGET_MEMBER", memberId);

    const request = {
      request: "mute",
      roomId: roomId,
      memberId: memberId,
    };
    dispatch("sendMessage", JSON.stringify(request), { root: true });
  },
  unmuteMember({ dispatch, commit }, { memberId, roomId }) {
    console.log("unmuteMember", memberId, roomId);
    commit("SET_TARGET_MEMBER", memberId);

    const request = {
      request: "unmute",
      roomId: roomId,
      memberId: memberId,
    };
    dispatch("sendMessage", JSON.stringify(request), { root: true });
  },
  hangupMember({ dispatch }, { memberId, roomId }) {
    const request = {
      request: "hangup",
      roomId: roomId,
      memberId: memberId,
    };
    dispatch("sendMessage", JSON.stringify(request), { root: true });
  },
  changeRoom({ dispatch }, { memberId, roomId, newRoomId }) {
    const request = {
      request: "transfer",
      roomId: roomId,
      newRoomId: newRoomId,
      memberId: memberId,
    };
    dispatch("sendMessage", JSON.stringify(request), { root: true });
  },
  startRecording({ dispatch }, { roomId }) {
    console.log("startRecording", roomId);
    const request = {
      request: "recording",
      action: "start",
      roomId: roomId,
    };
    dispatch("sendMessage", JSON.stringify(request), { root: true });
  },
  stopRecording({ dispatch }, { roomId, recordingId }) {
    console.log("stopRecording", roomId, recordingId);
    const request = {
      request: "recording",
      action: "stop",
      roomId: roomId,
      recordingId: recordingId,
    };
    dispatch("sendMessage", JSON.stringify(request), { root: true });
  },
  toggleExpand({ state }, { roomId, expanded }) {
    const room = state.rooms.find((room) => room.conference_name === roomId);
    if (room) {
      room.expanded = expanded;
    }
  },
  setShouldRecall({ commit }, value) {
    commit("SET_SHOULD_RECALL", value);
  },
  setTargetRecall({ commit }, call) {
    commit("SET_TARGET_RECALL", call);
  },
  setShouldCallFromNumber({ commit }, value) {
    commit("SET_SHOULD_CALL_FROM_NUMBER", value);
  },
  setTargetNumber({ commit }, number) {
    commit("SET_TARGET_NUMBER", number);
  },
  async wsListeners({ dispatch, rootState, commit, state }) {
    rootState.ws.addEventListener("message", (msg) => {
      const message = JSON.parse(msg.data);
      commit("ADD_MESSAGE", message);
      console.log(message);
      switch (message.action) {
        case "conference-create": {
          const room = {
            answered: true,
            conference_name: message.roomId,
            conference_uuid: message.sessionId,
            recording: false,
            hosts: [],
            member_count: 0,
            members: [],
          };
          commit("ADD_ROOM", room);
          break;
        }
        case "conference-destroy": {
          commit("REMOVE_ROOM", message.roomId);
          dispatch("toggleExpand", {
            roomId: message.roomId,
            expanded: false,
          });
          break;
        }
        case "add-member": {
          const callerIdNumber = message.callerIdNumber ? message.callerIdNumber : "UNKNOWN";

          const member = {
            id: message.memberId,
            type: "caller",
            uuid: message.uniqueId,
            caller_id_name: message.callerIdName,
            caller_id_number: callerIdNumber,
            called_number: message.calledNumber,
            recording_status: "off",
            flags: {
              can_hear: true,
              can_see: true,
              can_speak: true,
              end_conference: false,
              has_floor: true,
              has_video: false,
              hold: false,
              is_moderator: false,
              mute_detect: false,
              pass_digits: true,
              talking: false,
              video_bridge: false,
            },
          };
          commit("ADD_MEMBER", { roomId: message.roomId, member: member });
          dispatch("toggleExpand", {
            roomId: message.roomId,
            expanded: true,
          });
          break;
        }
        case "del-member": {
          commit("REMOVE_MEMBER", {
            roomId: message.roomId,
            memberId: message.memberId,
          });
          break;
        }
        case "mute-member": {
          commit("SET_MEMBER_FLAG", {
            roomId: message.roomId,
            memberId: state.target_member,
            flagName: "can_speak",
            flagValue: false,
          });
          commit("CLEAR_TARGET_MEMBER");

          break;
        }
        case "unmute-member": {
          commit("SET_MEMBER_FLAG", {
            roomId: message.roomId,
            memberId: state.target_member,
            flagName: "can_speak",
            flagValue: true,
          });
          commit("CLEAR_TARGET_MEMBER");
          break;
        }
        case "mark-user": {
          commit("ADD_HOST", {
            roomId: message.roomId,
            memberId: message.memberId,
          });
          break;
        }
        case "unmark-user": {
          commit("REMOVE_HOST", {
            roomId: message.roomId,
            memberId: message.memberId,
          });
          break;
        }
        case "list-rooms": {
          const defaultRoomNames = state.rooms.map(
            (room) => room.conference_name
          );

          const roomNames = message.rooms
            .filter((room) => defaultRoomNames.includes(room.conference_name))
            .map((room) => room.conference_name);

          const filteredDefaultRooms = state.rooms.filter(
            (room) => !roomNames.includes(room.conference_name)
          );

          commit("SET_ROOMS", message.rooms.concat(filteredDefaultRooms));
          break;
        }
        case "start-recording": {
          if (message.recordingPath) {
            const recordingId = message.recordingPath
              ?.split("/")
              ?.pop()
              ?.split(".")[0];
            // console.log("recordingId", recordingId);
            commit("START_ROOM_RECORDING", {
              roomId: message.roomId,
              recordingId: recordingId,
            });
          }
          break;
        }
        case "stop-recording": {
          commit("STOP_ROOM_RECORDING", message.roomId);
          break;
        }
      }
    });
  },
};

export const getters = {
  rooms: (state) => state.rooms,
  messages: (state) => state.messages,
  shouldRecall: (state) => state.shouldRecall,
  target_recall: (state) => state.target_recall,
  target_number: (state) => state.target_number,
  shouldCallFromNumber: (state) => state.shouldCallFromNumber,
};
