import axiosInstance, { USERS_URL, REGISTER_URL } from "./config.js";

export default {
  async listUsers() {
    const response = await axiosInstance.get(USERS_URL);
    return response;
  },
  async getUser(userId) {
    return axiosInstance.get(`${USERS_URL}/${userId}`);
  },
  async addUser({
    username,
    name,
    email,
    phoneNumber,
    callerId,
    timezone,
    password,
    permission,
    additional_permission,
  }) {
    const user = {
      username: username,
      fullName: name,
      email: email,
      password: password,
      phoneNumber: phoneNumber,
      callerId: callerId,
      timezone: timezone,
      role: permission,
      outreach: additional_permission,
    };

    const response = await axiosInstance.post(
      REGISTER_URL,
      JSON.stringify(user)
    );
    return { data: user, response: response };
  },
  deleteUser(userId) {
    return axiosInstance.delete(USERS_URL, { data: { username: userId } });
  },
  updateUser({
    userId,
    name,
    email,
    phone_number,
    callerId,
    timezone,
    password,
    permission,
    additional_permission
  }) {
    let data = {
      username: userId,
      fullName: name,
      email: email,
      phoneNumber: phone_number,
      callerId: callerId,
      timezone: timezone,
      role: permission,
      outreach: additional_permission,
    };

    if (password) {
      data["password"] = password;
    }

    return axiosInstance.post(USERS_URL, JSON.stringify(data));
  },
};
