import auth from "@/network/auth.js";
import StorageUtil from "@/utils/LocalStorageUtil.js";

export const namespaced = true;

export const state = {
  loading: false,
  errors: [],
  authData: null,
  authReverseData: null,
};

export const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_AUTH_DATA(state, data) {
    state.authData = data;
    StorageUtil.setUserAuthData(data);
  },
  CLEAR_AUTH_DATA() {
    state.authData = null;
    state.authReverseData = null;
    StorageUtil.removeUserAuthData();
    StorageUtil.removeUserAuthData(true);
    location.reload();
  },
  SET_REVERSE_AUTH_DATA() {
    const data = StorageUtil.getUserAuthData(true);
    state.authReverseData = data;
    StorageUtil.setUserAuthData(data, true);
  },
  SET_LOGIN_AS() {
    const data = StorageUtil.getUserAuthData();
    state.authReverseData = data;
    StorageUtil.setUserAuthData(data, true);
  },
  REVERT_LOGIN_AS(state){
    state.authReverseData = null;
    const data = StorageUtil.getUserAuthData(true);
    state.authData = data;
    StorageUtil.setUserAuthData(data);
    StorageUtil.removeUserAuthData(true);
    location.reload();
  },
  SET_ERROR_MESSAGES(state, errors) {
    state.errors = errors;
  },
};

export const actions = {
  async login({ commit }, { email, password }) {
    commit("SET_LOADING", true);
    commit("SET_ERROR_MESSAGES", []);
    try {
      const { data } = await auth.login(email, password);
      commit("SET_AUTH_DATA", data);
      return true;
    } catch (error) {
      commit(
        "SET_ERROR_MESSAGES",
        error.response?.data?.errors ?? [["Something went wrong"]]
      );
    } finally {
      commit("SET_LOADING", false);
    }
  },
  async logout({ commit, state }) {
    commit("SET_LOADING", true);
    commit("SET_ERROR_MESSAGES", []);
    try {
      console.log(
        "LOGING_OUT",
        state?.authData?.data?.username,
        state.authData
      );
      const { data } = await auth.logout(state?.authData?.data?.username);
      commit("SET_AUTH_DATA", data);
      return true;
    } catch (error) {
      commit(
        "SET_ERROR_MESSAGES",
        error.response?.data?.errors ?? [["Something went wrong"]]
      );
    } finally {
      commit("CLEAR_AUTH_DATA");
      commit("SET_LOADING", false);
    }
  },
};

export const getters = {
  loading: (state) => state.loading,
  authData: (state) => state.authData,
  errors: (state) => state.errors,
  userName: (state) => state.authData?.name,
  authReverseData: (state) => state.authReverseData,
};
