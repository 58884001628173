export const namespaced = true;

export const state = {
  missed_calls: [],
  search: "",
  number: "",
  from: "",
  to: "",
  page: 1,
  totals: 0,

  loading: false,
  error: null,
  pusher: null,
};

export const mutations = {
  SET_MISSED_CALLS(state, calls) {
    state.missed_calls = calls;
  },
  SET_TOTALS(state, value) {
    state.totals = value;
  },
  SET_PAGE(state, value) {
    state.page = value;
  },
  SET_SEARCH(state, { term, number }) {
    state.search = term;
    state.number = number;
  },
  SET_DATE_RANGE(state, { from, to }) {
    state.from = from;
    state.to = to;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
};

export const actions = {
  setMissedCalls({ commit }, calls) {
    commit("SET_MISSED_CALLS", calls);
    commit("SET_TOTALS", calls.length);
    commit("SET_LOADING", false);
  },
  async listMissedCalls({ dispatch, commit }, payload) {
    commit("SET_LOADING", true);
    const defaultLimit = 1000;
    const missedCallsLimit = payload?.limit ? payload?.limit : defaultLimit;

    const request = {
      request: "calls",
      action: "missed-calls",
      limit: missedCallsLimit,
    };
    dispatch("sendMessage", JSON.stringify(request), { root: true });
  },
  updatePage({ commit }, { page }) {
    commit("SET_PAGE", page);
  },
  setFilterValues({ commit }, { term, number, from, to }) {
    commit("SET_PAGE", 1);
    commit("SET_SEARCH", { term, number });
    commit("SET_DATE_RANGE", { from, to });
  },
  async wsListeners({ dispatch, rootState }) {
    rootState.ws.addEventListener("message", (msg) => {
      const message = JSON.parse(msg.data);
      switch (message.action) {
        case "missed-calls": {
          // console.log("missed-calls", message.calls);
          dispatch("setMissedCalls", message.calls);
          break;
        }
      }
    });
  },
};

export const getters = {
  missed_calls: (state) => state.missed_calls,

  target_calls: (state) => {
    const CALLS_PER_PAGE = 10;
    const lowerLimit = (state.page - 1) * CALLS_PER_PAGE;
    const upperLimit =
      lowerLimit + CALLS_PER_PAGE > state.missed_calls.length
        ? state.missed_calls.length
        : lowerLimit + CALLS_PER_PAGE;
    return state.missed_calls.slice(lowerLimit, upperLimit);
  },

  search: (state) => state.search,
  page: (state) => state.page,
  totals: (state) => state.totals,
  snackbarProps: (state) => state.snackbarProps,

  loading: (state) => state.loading,
  error: (state) => state.error,
};
