import prankApi from "@/network/prank";
export const namespaced = true;

export const state = {
  number: "",
  from: "",
  to: "",
  page: 1,
  totals: 0,

  pranks: [],

  loading: true,
  error: null,
};

export const mutations = {
  SET_LOADING(state, loading) {
    console.log("loading", loading);
    state.loading = loading;
  },
  SET_PHONE_NUMBER(state, number) {
    state.number = number;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_PRANKS(state, pranks) {
    state.pranks = pranks;
  },
  SET_PAGE(state, page) {
    state.page = page;
  },
  SET_TOTALS(state, total) {
    state.totals = total;
  },
  SET_FROM(state, from) {
    state.from = from;
  },
  SET_TO(state, to) {
    state.to = to;
  }
};

export const actions = {
  async listApprovedPranks({commit}, {page = 1, perPage = 3} = {}) {
    commit("SET_LOADING", true);
    const { data } = await prankApi.listApprovedPranks({ page, perPage });
    commit("SET_PRANKS", data.data);
    commit("SET_TOTALS", data.total);
    commit("SET_FROM", data.per_page * (data.current_page - 1) + 1);
    commit("SET_TO", data.to);
    commit("SET_LOADING", false);
  },
  updatePage({ commit }, { page }) {
    commit("SET_PAGE", page);
  },
  updatePhoneNumber({ commit }, { number }) {
      commit("SET_PHONE_NUMBER", number);
  }
};

export const getters = {
  pranks: (state) => state.pranks,
  selectedPhoneNumber: (state) => state.number,

  page: (state) => state.page,
  totals: (state) => state.totals,
  loading: (state) => state.loading,
  error: (state) => state.error,
  from: (state) => state.from,
  to: (state) => state.to
};
