export const namespaced = true;

export const state = {
  session: null,
  loading: false,
  error: null,
};

export const mutations = {
  SET_SESSION(state, session) {
    state.session = session;
  },

  SET_SESSION_NOTE(state, note) {
    state.session.notes = note;
  },

  SET_LOADING(state, loading) {
    state.loading = loading;
  },

  SET_ERROR(state, error) {
    state.error = error;
  },
};

export const actions = {
  setSessionForNotes({ commit }, { session }) {
    commit("SET_SESSION", session);
  },

  async saveNote({ dispatch, commit, state }, { note}) {
    commit("SET_LOADING", true);
    commit("SET_ERROR", null);

    try {
      const request = {
        request: "sessions",
        action: "edit-notes",
        sessionId: state.session.sessionId,
        notes: note,
      };

      dispatch("sendMessage", JSON.stringify(request), { root: true });
    } catch (error) {
      commit(
        "SET_ERROR",
        error?.response?.data?.message ?? "Something went wrong"
      );
    } finally {
      commit("SET_LOADING", false);
    }
  },
  async wsListeners({ rootState, commit }) {
    rootState.ws.addEventListener("message", (msg) => {
      const message = JSON.parse(msg.data);
      switch (message.action) {
        case "session-edit-notes": {
          commit("SET_SESSION_NOTE", message.note.notes);
          break;
        }
      }
    });
  },
};

export const getters = {
  session: (state) => state.session,
  loading: (state) => state.loading,
  error: (state) => state.error,
};
