import userApi from "@/network/users.js";
export const namespaced = true;

export const state = {
  loading: false,
  deleting: false,
  adding: false,
  saving: false,
  errorMessage: null,
  users: [],
  page: 1,
  totals: 0,
};

export const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR_MESSAGE(state, error) {
    state.errorMessage = error;
  },
  SET_TOTALS(state, value) {
    state.totals = value;
  },
  SET_PAGE(state, value) {
    state.page = value;
  },
  SET_USERS(state, users) {
    state.users = users;
  },
  DELETE_USER(state, userId) {
    state.users = state.users.filter((user) => user.id !== userId);
  },
  ADD_USER(state, user) {
    state.users.push(user);
  },
  SET_DELETING(state, deleting) {
    state.deleting = deleting;
  },
  SET_ADDING(state, adding) {
    state.adding = adding;
  },
  SET_SAVING(state, saving) {
    state.saving = saving;
  },
};

export const actions = {
  async listUsers({ commit }) {
    commit("SET_LOADING", true);
    commit("SET_ERROR_MESSAGE", null);
    try {
      const { data } = await userApi.listUsers(state.page);
      commit("SET_USERS", data?.users);
      commit("SET_TOTALS", data?.users?.length);
    } catch (error) {
      commit(
        "SET_ERROR_MESSAGE",
        error?.response?.data?.message ?? "Something went wrong"
      );
    } finally {
      commit("SET_LOADING", false);
    }
  },
  async deleteUser({ commit }, userId) {
    commit("SET_DELETING", true);
    commit("SET_ERROR_MESSAGE", null);
    try {
      await userApi.deleteUser(userId);
      commit("DELETE_USER", userId);
    } catch (error) {
      commit(
        "SET_ERROR_MESSAGE",
        error?.response?.data?.message ?? "Something went wrong"
      );
    } finally {
      commit("SET_DELETING", false);
    }
  },
  async addUser(
    { commit },
    {
      username,
      name,
      email,
      phoneNumber,
      callerId,
      timezone,
      password,
      permission,
      additional_permission,
    }
  ) {
    commit("SET_ADDING", true);
    commit("SET_ERROR_MESSAGE", null);
    try {
      const { data } = await userApi.addUser({
        username,
        name,
        email,
        phoneNumber,
        callerId,
        timezone,
        password,
        permission,
        additional_permission,
      });
      commit("ADD_USER", data);
    } catch (error) {
      commit(
        "SET_ERROR_MESSAGE",
        error?.response?.data?.message ?? "Something went wrong"
      );
    } finally {
      commit("SET_ADDING", false);
    }
  },
  async saveUser(
    { commit },
    {
      userId,
      name,
      email,
      phone,
      callerId,
      timezone,
      password,
      permission,
      additional_permission,
    }
  ) {
    commit("SET_SAVING", true);
    commit("SET_ERROR_MESSAGE", null);
    try {
      await userApi.updateUser({
        userId,
        name,
        email,
        phone,
        callerId,
        timezone,
        password,
        permission,
        additional_permission,
      });
    } catch (error) {
      commit(
        "SET_ERROR_MESSAGE",
        error?.response?.data?.message ?? "Something went wrong"
      );
    } finally {
      commit("SET_SAVING", false);
    }
  },
  async getUser({ commit }, userId) {
    commit("SET_LOADING", true);
    commit("SET_ERROR_MESSAGE", null);
    try {
      const { data } = await userApi.getUser(userId);
      return data?.users.shift();
      //commit("DELETE_USER", userId);
    } catch (error) {
      commit(
        "SET_ERROR_MESSAGE",
        error?.response?.data?.message ?? "Something went wrong"
      );
    } finally {
      commit("SET_LOADING", false);
    }
  },
  updatePage({ commit }, { page }) {
    commit("SET_PAGE", page);
  },
};

export const getters = {
  loading: (state) => state.loading,
  page: (state) => state.page,
  totals: (state) => state.totals,
  users: (state) => state.users,
  errorMessage: (state) => state.errorMessage,
  deleting: (state) => state.deleting,
  adding: (state) => state.adding,
  saving: (state) => state.saving,
};
