import axios from "axios";
import StorageUtil from "@/utils/LocalStorageUtil.js";

export default axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL,
  timeout: 15000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",

    ...(StorageUtil.getUserAuthToken() && {
      Token: StorageUtil.getUserAuthToken(),
    }),
  },
});

export const axiosFormData = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL,
  timeout: 15000,
  headers: {
    "Content-Type": "multipart/form-data",

    ...(StorageUtil.getUserAuthToken() && {
      Token: StorageUtil.getUserAuthToken(),
    }),
  },
});

export const axiosOwnagePranksInstance = axios.create({
    baseURL: process.env.VUE_APP_OWNAGE_PRANKS_BASE_URL,
    timeout: 30000,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: process.env.VUE_APP_OWNAGE_PRANKS_API_KEY,
    },
});

export const LOGIN_URL = "/api/auth/login";
export const LOGINAS_URL = "/api/auth/loginAs";
export const LOGOUT_URL = "/api/auth/logout";
export const REGISTER_URL = "/api/auth/register";
export const USERS_URL = "/api/auth/users";
export const DEVICES_URL = "/api/devices";
export const INBOUNDS_URL = "/api/inbounds";
export const GREETING_URL = "/api/voicemail/greeting";


export const LIST_CONTACTS_URL = "/sms-api/contacts";
export const LIST_NUMBERS_URL = "/sms-api/numbers";
export const SEND_SMS_URL = "/sms-api/message";
export const UPLOAD_MMS_FILE = "/sms-api/mms-upload";
export const MARK_SMS_AS_SEEN_URL = "/sms-api/mark-as-seen";
export const DELETE_SMS_URL = "/sms-api/delete-message";
export const RESTORE_SMS_URL = "/sms-api/restore-message";
export const OUTREACH_FLAG = 1;
export const APPROVED_PRANKS = '/approved-pranks';
