<template>
  <nav
    aria-label="Sidebar"
    class="block flex-shrink-0 bg-gray-800 overflow-y-auto"
  >
    <div class="relative w-20 flex flex-col p-3 space-y-1">
      <sidebar-link label="Live" to="/live">
        <PhoneIcon />
      </sidebar-link>

      <sidebar-link
        v-if="userType !== 'listen_only'"
        label="Recording"
        to="/recording"
      >
        <MicrophoneIcon />
      </sidebar-link>

      <sidebar-link
        v-if="userType !== 'listen_only'"
        label="Inbounds"
        to="/inbounds"
      >
        <PhoneIncomingIcon />
      </sidebar-link>

      <sidebar-link
        v-if="userType !== 'listen_only'"
        label="Contacts"
        to="/contacts"
      >
        <BookOpenIcon />
      </sidebar-link>

      <!-- <sidebar-link v-if="userType === 'admin'" label="Devices" to="/devices">
        <DeviceMobileIcon />
      </sidebar-link> -->

      <sidebar-link
        v-if="['admin', 'conference_manager'].includes(userType)"
        label="Greetings"
        to="/greetings"
      >
        <PlayIcon />
      </sidebar-link>

      <sidebar-link v-if="userType === 'admin'" label="Users" to="/users">
        <UserGroupIcon />
      </sidebar-link>

      <sidebar-link
        v-if="userType !== 'listen_only'"
        label="Voice Mail Messages"
        to="/voicemail"
      >
        <AtSymbolIcon />
      </sidebar-link>

      <sidebar-link v-if="userType !== 'listen_only'" label="SMS" to="/sms">
        <MailIcon />
      </sidebar-link>

      <sidebar-link v-if="isOutreachAllow" label="Outreach" to="/outreach">
        <ChatAltIcon />
      </sidebar-link>

      <sidebar-link
        v-if="userType !== 'listen_only'"
        label="Queue"
        to="/queues"
      >
        <ClockIcon />
      </sidebar-link>

      <sidebar-link v-if="userType === 'admin'" label="Settings" to="/settings">
        <CogIcon />
      </sidebar-link>
    </div>
  </nav>
</template>

<script>
import SidebarLink from "./SidebarLink.vue";
import StorageUtil from "@/utils/LocalStorageUtil.js";

import {
  PhoneIcon,
  MicrophoneIcon,
  PhoneIncomingIcon,
  BookOpenIcon,
  // DeviceMobileIcon,
  UserGroupIcon,
  AtSymbolIcon,
  ClockIcon,
  MailIcon,
  ChatAltIcon,
  CogIcon,
  PlayIcon,
} from "@heroicons/vue/solid";

export default {
  components: {
    SidebarLink,
    PhoneIcon,
    MicrophoneIcon,
    PhoneIncomingIcon,
    BookOpenIcon,
    // DeviceMobileIcon,
    UserGroupIcon,
    AtSymbolIcon,
    ClockIcon,
    MailIcon,
    ChatAltIcon,
    CogIcon,
    PlayIcon,
  },
  computed: {
    userType() {
      return StorageUtil.getUserType();
    },
    isOutreach() {
      return StorageUtil.getUserData()?.outreach;
    },
    isOutreachAllow() {
      if (this.userType === "admin") {
        return true;
      }

      if (this.userType === "conference_manager" && this.isOutreach === "1") {
        return true;
      }

      return false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
