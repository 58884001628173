<template>
  <Menu as="span" class="relative block">
    <MenuButton
      class="h-11 relative inline-flex items-center px-2 py-2 rounded-l-md text-sm font-medium text-ownage-100 bg-white hover:text-ownage-900 focus:outline-none"
    >
      <span class="sr-only">Open options</span>
      <VolumeUpIcon class="h-5 w-5" aria-hidden="true" />
    </MenuButton>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        class="z-50 origin-top-right absolute right-0 mt-2 -mr-1 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <div class="p-4">
          <div class="flex items-center space-x-3 pr-2">
            <button
              @click="toggleAll({ value: !allMuteStatus })"
              title="Mute speaker"
              type="button"
              class="inline-flex items-center px-3 py-2 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none"
            >
              <VolumeUpIcon
                v-if="!allMuteStatus"
                class="h-4 w-4"
                aria-hidden="true"
              />
              <VolumeOffIcon
                v-if="allMuteStatus"
                class="h-4 w-4"
                aria-hidden="true"
              />
            </button>
            <span> All </span>
          </div>
          <div class="mt-2">
            <button
              @click="setMute({ value: !incomingMuted })"
              title="Mute speaker"
              type="button"
              class="inline-flex items-center px-3 py-2 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none"
            >
              <VolumeUpIcon
                v-if="!incomingMuted"
                class="h-4 w-4"
                aria-hidden="true"
              />
              <VolumeOffIcon
                v-if="incomingMuted"
                class="h-4 w-4"
                aria-hidden="true"
              />
            </button>
            <span> Calls </span>
          </div>
          <div class="mt-2">
            <button
              @click="setSMSMute({ value: !incomingSMSMuted })"
              title="Mute speaker"
              type="button"
              class="inline-flex items-center px-3 py-2 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none"
            >
              <VolumeUpIcon
                v-if="!incomingSMSMuted"
                class="h-4 w-4"
                aria-hidden="true"
              />
              <VolumeOffIcon
                v-if="incomingSMSMuted"
                class="h-4 w-4"
                aria-hidden="true"
              />
            </button>
            <span> SMS </span>
          </div>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Menu, MenuButton, MenuItems } from "@headlessui/vue";
import { VolumeOffIcon, VolumeUpIcon } from "@heroicons/vue/outline";

export default {
  components: {
    Menu,
    MenuButton,
    MenuItems,
    VolumeUpIcon,
    VolumeOffIcon,
  },
  data() {
    return {
      incomingSMSMuted: false,
    };
  },
  methods: {
    toggleAll(value) {
      this.setMute(value);
      this.setSMSMute(value);
    },
    setSMSMute({ value }) {
      this.incomingSMSMuted = value;
    },
    ...mapActions("incomingCalls", ["setMute"]),
    // ...mapActions("smsStore", ["setSMSMute"]),
  },
  computed: {
    ...mapGetters("incomingCalls", ["incomingMuted"]),
    // ...mapGetters("smsStore", ["incomingSMSMuted"]),
    allMuteStatus() {
      return this.incomingMuted && this.incomingSMSMuted;
    },
  },
};
</script>

<style></style>
