export const namespaced = true;

export const state = {
  sessions: [],
  incomingMuted: false,
};

export const mutations = {
  SET_SESSIONS(state, sessions) {
    state.sessions = sessions;
  },
  ADD_SESSION(state, session) {
    state.sessions.unshift(session);
  },
  REMOVE_SESSION(state, session) {
    const targetSession = state.sessions.find(
      (item) => item.uniqueId === session.uniqueId
    );
    const index = state.sessions.indexOf(targetSession);
    if (index > -1) {
      state.sessions.splice(index, 1);
    }
  },
  SET_MUTE(state, value) {
    state.incomingMuted = value;
  },
};

export const actions = {
  addSession({ commit }, { session }) {
    if (!state.incomingMuted) {
      const tuneAudio = new Audio(
        "https://v88-assets.s3.us-east-2.amazonaws.com/tune.mp3"
      );
      tuneAudio.play();
    }
    // console.log("ADD_SESSION", session);
    commit("ADD_SESSION", session);
  },

  removeSession({ commit }, { session }) {
    commit("REMOVE_SESSION", session);
  },

  setMute({ commit }, { value }) {
    commit("SET_MUTE", value);
  },
  async wsListeners({ dispatch, rootState }) {
    rootState.ws.addEventListener("message", (msg) => {
      const message = JSON.parse(msg.data);
      switch (message.action) {
        case "incoming-call": {
          const session = {
            calledNumber: message.calledNumber,
            callerIdName: message.callerIdName,
            callerIdNumber: message.callerIdNumber,
            memberId: message.memberId,
            uniqueId: message.uniqueId,
          };
          dispatch("addSession", { session: session });
          break;
        }
        case "incoming-call-cancel": {
          const session = {
            hangupCause: message.hangupCause,
            uniqueId: message.uniqueId,
          };
          dispatch("removeSession", { session: session });
          break;
        }
      }
    });
  },
  answerCall({ dispatch }, { roomId, uniqueId }) {
    const request = {
      request: "answer",
      roomId: roomId ?? "Main",
      uniqueId: uniqueId,
    };
    dispatch("sendMessage", JSON.stringify(request), { root: true });
  },
  declineCall({ dispatch }, { uniqueId }) {
    const request = {
      request: "decline",
      uniqueId: uniqueId,
    };
    dispatch("sendMessage", JSON.stringify(request), { root: true });
  },
  sendToVoicemail({ dispatch }, { calledNumber, uniqueId }) {
    const request = {
      request: "voicemail",
      calledNumber: calledNumber,
      uniqueId: uniqueId,
    };
    dispatch("sendMessage", JSON.stringify(request), { root: true });
  },
};

export const getters = {
  sessions: (state) => state.sessions,
  incomingMuted: (state) => state.incomingMuted,
};
