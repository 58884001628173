<template>
  <main class="pb-2" v-if="errors">
    <Transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <div class="mx-auto px-4 sm:px-6 lg:px-8">
        <div class="pt-4 grid grid-cols-1 gap-4 items-start">
          <div class="grid grid-cols-1 gap-4 lg:col-span-2">
            <div
              v-for="error in errors"
              :key="error.id"
              class="rounded-lg bg-white overflow-hidden shadow"
            >
              <ErrorAlert :key="error.id" :text="error.message" />
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </main>
</template>

<script>
import ErrorAlert from "@/components/base/ErrorAlert.vue";
import { mapGetters } from "vuex";
export default {
  setup() {
    return {};
  },
  components: {
    ErrorAlert,
  },
  computed: {
    ...mapGetters(["errors"]),
  },
};
</script>

<style lang="scss" scoped></style>
