export default class Utils {
  static setUserAuthData = (data, reverse = false) => {
    const targetKey = reverse ? "reverseUser" : "user";
    localStorage.setItem(targetKey, JSON.stringify(data));
  };

  static removeUserAuthData = (reverse = false) => {
    const targetKey = reverse ? "reverseUser" : "user";
    localStorage.removeItem(targetKey);
  };

  static getUserAuthData = (reverse = false) => {
    const targetKey = reverse ? "reverseUser" : "user";
    const jsonData = JSON.parse(localStorage.getItem(targetKey));
    if (jsonData) {
      return jsonData;
    }
  };

  static getUserAuthToken = (reverse = false) => {
    const targetKey = reverse ? "reverseUser" : "user";
    const jsonData = JSON.parse(localStorage.getItem(targetKey));
    if (jsonData) {
      return jsonData?.data?.token;
    }
  };

  static getUserType = (reverse = false) => {
    const targetKey = reverse ? "reverseUser" : "user";
    const jsonData = JSON.parse(localStorage.getItem(targetKey));
    if (jsonData) {
      return jsonData?.data?.role;
    }
  };

  static getUserTimezone = (reverse = false) => {
    const targetKey = reverse ? "reverseUser" : "user";
    const jsonData = JSON.parse(localStorage.getItem(targetKey));
    if (jsonData) {
      return jsonData?.data?.timezone;
    }
  };
  
  static getUserData = (reverse = false) => {
    const targetKey = reverse ? "reverseUser" : "user";
    const jsonData = JSON.parse(localStorage.getItem(targetKey));
    if (jsonData) {
      return jsonData?.data;
    }
  };
}
