import {
  axiosOwnagePranksInstance,
  APPROVED_PRANKS,
} from "./config.js";

function getQueryString(params) {
  return Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join("&");
}

export default {
  async listApprovedPranks({ page, perPage }) {
    let listUrl = APPROVED_PRANKS;
    let params = {};
    if (perPage !== null) params["per_page"] = perPage;
    if (page !== null) params["page"] = page;
    const qs = getQueryString(params);
    if (qs) listUrl += `?${qs}`;
    console.log({ listUrl });
    return axiosOwnagePranksInstance.get(listUrl);
  },
};
