<template>
  <div class="min-w-0 flex-1 flex items-center justify-between">
    <div class="min-w-0 flex-1 ml-4">
      <h2
        class="text-2xl font-bold leading-7 text-gray-900 sm:text-2xl sm:truncate"
      >
        {{ title }}
      </h2>
    </div>
    <AppWebrtc />
    <div class="ml-4 pr-4 flex-shrink-0 flex items-center space-x-10">
      <div class="flex items-center space-x-8">
        <NotificationSoundMenu />
        <span class="inline-flex">
          <a
            href="#"
            class="-mx-1 bg-white p-1 rounded-full text-gray-400 hover:text-gray-500"
          >
            <span class="sr-only">View notifications</span>
            <svg
              class="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="#ffa30c"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
              />
            </svg>
          </a>
        </span>

        <div class="relative inline-block text-left">
          <ProfileMenu />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileMenu from "./ProfileMenu.vue";
import AppWebrtc from "@/modules/webrtc/AppWebrtc.vue";
import NotificationSoundMenu from "./NotificationSoundMenu.vue";
export default {
  components: { ProfileMenu, AppWebrtc, NotificationSoundMenu },
  props: {
    title: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped></style>
