export const namespaced = true;

export const state = {
  activeSessionID: null,
  recordings: [],
  sessions: [],
  raw_sessions: [],
  search: "",
  number: "",
  from: "",
  to: "",
  recordingOnly: 1,
  page: 1,
  totals: 0,
  session: null,
  session_calls: [],
  snackbarProps: null,
  hideEmptyCalls: true,

  sessionRecordings: null,
  loading: false,
  error: null,
  pusher: null,
};

export const mutations = {
  SET_RECORDINGS(state, recordings) {
    state.recordings = recordings;
  },
  SET_SESSIONS(state, sessions) {
    state.sessions = sessions;
  },
  SET_RAW_SESSIONS(state, sessions) {
    state.raw_sessions = sessions;
  },
  SET_TOTALS(state, value) {
    state.totals = value;
  },
  SET_PAGE(state, value) {
    state.page = value;
  },
  SET_RECORDING_ONLY(state, value) {
    state.recordingOnly = value;
  },
  SET_SEARCH(state, { term, number }) {
    state.search = term;
    state.number = number;
  },
  SET_DATE_RANGE(state, { from, to }) {
    state.from = from;
    state.to = to;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_HIDE_EMPTY_CALLS(state, value) {
    state.hideEmptyCalls = value;
  },
  SET_ACTIVE_SESSION(state, sessionID) {
    state.activeSessionID = sessionID;
  },
  SET_SESSION(state, session) {
    state.session = session;
    state.session_calls = [];
  },
  UPDATE_CALL_NOTES(state, call) {
    const index = state.session_calls.findIndex(
      (item) => item.uniqueId === call.uniqueId
    );
    state.session_calls[index].notes = call.notes;
  },
  UPDATE_SESSION_NOTES(state, session) {
    const index = state.raw_sessions.findIndex(
      (item) => item.sessionId === session.sessionId
    );
    state.raw_sessions[index].notes = session.notes;
    state.session.notes = session.notes;
  },
  APPEND_SESSION_CALL(state, call) {
    const recordings = state.recordings.filter(
      (recording) => recording.sessionId === call.uniqueId
    );
    console.log("store append=>", recordings)
    if (recordings.length > 0) {
      call.recordings = recordings;
    }
    state.session_calls.push(call);
  },
  SET_SESSION_RECORDINGS(state, recordings) {
    state.sessionRecordings = recordings;
  },

  SNACKBAR_PROPS(state, props) {
    state.snackbarProps = props;
  },
};

export const actions = {
  setSessions({ dispatch, commit, state }, sessions) {
    commit("SET_RAW_SESSIONS", sessions);
    // console.log("SET_SESSIONS", sessions);

    if (state.from !== "" && state.to !== "") {
      const fromDate = new Date(Date.parse(state.from + " UTC"));
      const toDate = new Date(Date.parse(state.to + " UTC"));
      sessions = sessions.filter(
        (session) =>
          fromDate <
          new Date(Date.parse(new Date(session.startedAt) + " UTC")) &&
          Date.parse(new Date(session.startedAt) + " UTC") < toDate
      );
    }

    if (state.search !== "") {
      sessions = sessions.filter(
        (session) => session.sessionId === state.search
      );
    }

    if (state.number !== "") {
      sessions = sessions.filter(
        (session) =>
          session.calls
            .find(
              (call) =>
                call.calledNumber.toLowerCase() === state.number.toLowerCase()
            )
            ?.calledNumber.toLowerCase() === state.number.toLowerCase()
      );
    }

    if (state.recordingOnly) {
      sessions = sessions.filter(
        (session) =>
          session.sessionId ===
          state.recordings.find(
            (recording) => recording.sessionId == session.sessionId
          )?.sessionId
      );
    }

    if (state.activeSessionID) {
      dispatch('setSession', state.activeSessionID);
    }

    commit("SET_SESSIONS", sessions);
    commit("SET_TOTALS", sessions.length);
    commit("SET_LOADING", false);
  },
  setRecordings({ commit, state, dispatch }, recordings) {
    commit("SET_RECORDINGS", recordings);
    commit("SET_LOADING", false);

    if (state.activeSessionID) {
      dispatch('setSessionRecordings', state.activeSessionID);
    }
  },
  listRecordings({ dispatch, commit }, payload) {
    commit("SET_LOADING", true);
    const defaultLimit = 100;
    const sessionLimit = payload?.limit ? payload?.limit : defaultLimit;

    const request = {
      request: "recordings",
      limit: sessionLimit,
    };
    dispatch("sendMessage", JSON.stringify(request), { root: true });
  },
  listSessions({ dispatch, commit }, payload) {
    commit("SET_LOADING", true);
    const defaultLimit = 100;
    const sessionLimit = payload?.limit ? payload?.limit : defaultLimit;

    const request = {
      request: "sessions",
      action: "list",
      limit: sessionLimit,
    };
    dispatch("sendMessage", JSON.stringify(request), { root: true });
  },
  updatePage({ commit }, { page }) {
    commit("SET_PAGE", page);
  },
  updateHideEmptyCalls({ commit }, hideEmptyCalls) {
    commit("SET_HIDE_EMPTY_CALLS", hideEmptyCalls);
  },

  setSession({ dispatch, commit, state }, sessionId) {
    commit("SET_ACTIVE_SESSION", sessionId);
    const session = state.raw_sessions.find(
      (session) => session.sessionId === sessionId
    );
    commit("SET_SESSION", session);

    const recordings = state.recordings.filter(
      (recording) => recording.sessionId === sessionId
    );
    commit("SET_SESSION_RECORDINGS", recordings);

    if (session) {
      session?.calls?.forEach((call) =>
        dispatch("getCall", { uniqueId: call.uniqueId }, { root: true })
      );
    }
  },
  setSessionRecordings({ commit, state }, sessionId) {
    const recordings = state.recordings.filter(
      (recording) => recording.sessionId === sessionId
    );
    commit("SET_SESSION_RECORDINGS", recordings);
  },
  setFilterValues({ commit }, { term, number, from, to, recordingOnly }) {
    commit("SET_PAGE", 1);
    commit("SET_SEARCH", { term, number });
    commit("SET_DATE_RANGE", { from, to });
    commit("SET_RECORDING_ONLY", recordingOnly);
  },
  async wsListeners({ dispatch, rootState, commit }) {
    rootState.ws.addEventListener("message", (msg) => {
      const message = JSON.parse(msg.data);
      switch (message.action) {
        case "list-recordings":
          // console.log("list-recordings", message.recordings);
          dispatch("setRecordings", message.recordings);
          break;
        case "list-sessions":
          // console.log("list-sessions", message.sessions);
          dispatch("setSessions", message.sessions);
          break;
        case "list-call":
          commit("APPEND_SESSION_CALL", message.call.pop());
          break;
        case "call-edit-notes":
          commit("UPDATE_CALL_NOTES", message.note);
          break;
        case "session-edit-notes":
          commit("UPDATE_SESSION_NOTES", message.note);
          break;
      }
    });
  },
};

export const getters = {
  sessions: (state) => state.sessions,
  recordings: (state) => state.recordings,
  session: (state) => state.session,
  session_calls: (state) => state.session_calls,
  sessionRecordings: (state) => state.sessionRecordings,

  target_sessions: (state) => {
    const SESSIONS_PER_PAGE = 10;
    const lowerLimit = (state.page - 1) * SESSIONS_PER_PAGE;
    const upperLimit =
      lowerLimit + SESSIONS_PER_PAGE > state.sessions.length
        ? state.sessions.length
        : lowerLimit + SESSIONS_PER_PAGE;
    return state.sessions.slice(lowerLimit, upperLimit);
  },

  hideEmptyCalls: (state) => state.hideEmptyCalls,

  search: (state) => state.search,
  page: (state) => state.page,
  totals: (state) => state.totals,
  snackbarProps: (state) => state.snackbarProps,

  loading: (state) => state.loading,
  error: (state) => state.error,
};
