export const namespaced = true;

export const state = {
  recent_calls: [],
  search: "",
  page: 1,
  totals: 0,

  loading: false,
  error: null,
  pusher: null,
};

export const mutations = {
  SET_RECENT_CALLS(state, calls) {
    state.recent_calls = calls;
  },
  SET_TOTALS(state, value) {
    state.totals = value;
  },
  SET_PAGE(state, value) {
    state.page = value;
  },
  SET_SEARCH_TERM(state, term) {
    state.search = term;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
};

export const actions = {
  setRecentCalls({ commit }, calls) {
    if (state.search !== "") {
      calls = calls.filter((call) => call.calledNumber === state.search);
    }

    commit("SET_RECENT_CALLS", calls);
    commit("SET_TOTALS", calls.length);
    commit("SET_LOADING", false);
  },
  async listRecentCalls({ dispatch, commit }, payload) {
    commit("SET_LOADING", true);
    const defaultLimit = 1000;
    const recentCallsLimit = payload?.limit ? payload?.limit : defaultLimit;

    const request = {
      request: "calls",
      action: "recent-calls",
      limit: recentCallsLimit,
    };
    dispatch("sendMessage", JSON.stringify(request), { root: true });
  },
  updatePage({ commit }, { page }) {
    commit("SET_PAGE", page);
  },
  setSearchTerm({ commit }, term) {
    console.log("SEARCH_TERM", term);
    commit("SET_SEARCH_TERM", term);
    commit("SET_PAGE", 1);
  },
  async wsListeners({ dispatch, rootState }) {
    rootState.ws.addEventListener("message", (msg) => {
      const message = JSON.parse(msg.data);
      switch (message.action) {
        case "recent-calls": {
          // console.log("recent-calls", message.calls);
          dispatch("setRecentCalls", message.calls);
          break;
        }
      }
    });
  },
};

export const getters = {
  recent_calls: (state) => state.recent_calls,

  target_calls: (state) => {
    const CALLS_PER_PAGE = 10;
    const lowerLimit = (state.page - 1) * CALLS_PER_PAGE;
    const upperLimit =
      lowerLimit + CALLS_PER_PAGE > state.recent_calls.length
        ? state.recent_calls.length
        : lowerLimit + CALLS_PER_PAGE;
    return state.recent_calls.slice(lowerLimit, upperLimit);
  },

  search: (state) => state.search,
  page: (state) => state.page,
  totals: (state) => state.totals,
  snackbarProps: (state) => state.snackbarProps,

  loading: (state) => state.loading,
  error: (state) => state.error,
};
