import smsApi from "@/network/sms";
import StorageUtil from "@/utils/LocalStorageUtil";
export const namespaced = true;

export const state = {
  contacts: [],
  outreach_contacts: [],
  incomingSMSMuted: false,
  screenshotMode: false,
  outreachMode: false,
  loading: false,
  error: null,
};

export const mutations = {
  ADD_MESSAGE(state, message) {
    const phone_number = message.receive ? message.from : message.to;
    const did_number = message.receive ? message.to : message.from;
    const is_seen = message.seen_user_ids.includes(StorageUtil.getUserData()?.username);

    let contactIndex = state.contacts.findIndex(
      (contact) => contact.number === phone_number
    );

    if (contactIndex === -1) {
      state.contacts.push({
        number: phone_number,
        messages_dids: {},
      });
    }

    contactIndex = state.contacts.findIndex(
      (contact) => contact.number === phone_number
    );

    state.contacts[contactIndex]["last_message_text"] = message.message;
    state.contacts[contactIndex]["last_message_time"] = message.received_at;
    state.contacts[contactIndex]["last_message_did"] = did_number;
    state.contacts[contactIndex]["seen"] = is_seen;

    if (!(did_number in state.contacts[contactIndex]["messages_dids"])) {
      state.contacts[contactIndex]["messages_dids"][did_number] = {
        messages: [],
      };
    }

    state.contacts[contactIndex]["messages_dids"][did_number]["seen"] = is_seen;
    state.contacts[contactIndex]["messages_dids"][did_number]["messages"].push(
      message
    );
  },
  SET_CONTACTS(state, contacts) {
    state.contacts = contacts;
  },
  SET_SMS_MUTE(state, value) {
    state.incomingSMSMuted = value;
  },
  SET_SCREENSHOT_MODE(state, screenshotMode) {
    state.screenshotMode = screenshotMode;
  },
  SET_OUTREACH_MODE(state, outreachMode) {
    state.outreachMode = outreachMode;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },

  SET_ERROR(state, error) {
    state.error = error;
  },
};

export const actions = {
  async listContacts({ commit, dispatch }, { phone_number, outreach }) {
    commit("SET_LOADING", true);
    commit("SET_ERROR", null);

    try {
      const { data } = await smsApi.listContacts(phone_number, outreach);
      commit("SET_CONTACTS", data.contacts ?? []);
    } catch (error) {
      dispatch(
        "addError",
        {
          error:
            error?.response?.data?.message ?? "(SMS) - Something went wrong",
          errorType: "sms",
        },
        { root: true }
      );
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async sendSMS(
    { dispatch, commit },
    { didNumber, number, textMessage, file }
  ) {
    commit("SET_LOADING", true);
    commit("SET_ERROR", null);

    try {
      let media_urls = [];
      if (file) {
        let formData = new FormData();
        formData.append("mms_file", file);
        const { data } = await smsApi.uploadMMSFile(formData);
        if (data.mms_file_url) {
          media_urls.push(data.mms_file_url);
        }
      }
      await smsApi.sendSMS(didNumber, number, textMessage, media_urls);
    } catch (error) {
      dispatch(
        "addError",
        {
          error:
            error?.response?.data?.message ?? "(SMS) - Something went wrong",
          errorType: "sms",
        },
        { root: true }
      );
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async deleteMessage({ dispatch, commit }, { id, outreach }) {
    commit("SET_LOADING", true);
    commit("SET_ERROR", null);

    try {
      await smsApi.deleteMessage(id);
      dispatch(
        "smsStore/listContacts",
        { phone_number: null, outreach: outreach },
        { root: true }
      );
    } catch (error) {
      dispatch(
        "addError",
        {
          error:
            error?.response?.data?.message ?? "(SMS) - Something went wrong",
          errorType: "sms",
        },
        { root: true }
      );
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async restoreMessage({ dispatch, commit }, { id, outreach }) {
    commit("SET_LOADING", true);
    commit("SET_ERROR", null);

    try {
      await smsApi.restoreMessage(id);
      dispatch(
        "smsStore/listContacts",
        { phone_number: null, outreach: outreach },
        { root: true }
      );
    } catch (error) {
      dispatch(
        "addError",
        {
          error:
            error?.response?.data?.message ?? "(SMS) - Something went wrong",
          errorType: "sms",
        },
        { root: true }
      );
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async markAsSeen({ dispatch, commit }, { didNumber, number }) {
    commit("SET_LOADING", true);
    commit("SET_ERROR", null);

    try {
      await smsApi.markAsSeen(didNumber, number);
    } catch (error) {
      dispatch(
        "addError",
        {
          error:
            error?.response?.data?.message ?? "(SMS) - Something went wrong",
          errorType: "sms",
        },
        { root: true }
      );
    } finally {
      commit("SET_LOADING", false);
    }
  },

  setSMSMute({ commit }, { value }) {
    commit("SET_SMS_MUTE", value);
  },

  setScreenshotMode({ commit }, value) {
    commit("SET_SCREENSHOT_MODE", value);
  },
  setOutreachMode({ commit }, value) {
    commit("SET_OUTREACH_MODE", value);
  },
  async wsListeners({ rootState, commit }) {
    rootState.ws.addEventListener("message", (msg) => {
      const message = JSON.parse(msg.data);
      switch (message.action) {
        case "new-message": {
          commit("ADD_MESSAGE", message.payload);
          break;
        }
      }
    });
  },
};

export const getters = {
  contacts: (state) => state.contacts,
  outreach_contacts: (state) => state.outreach_contacts,
  incomingSMSMuted: (state) => state.incomingSMSMuted,
  screenshotMode: (state) => state.screenshotMode,
  outreachMode: (state) => state.outreachMode,
};
