<template>
  <main
    :class="[
      title === 'SMS' || title === 'Outreach' ? '' : 'pb-16 pt-4',
      'min-w-0 flex-1 border-t border-gray-200 lg:flex',
    ]"
  >
    <section
      aria-labelledby="primary-heading"
      class="min-w-0 flex-1 h-full flex flex-col overflow-hidden lg:order-last"
    >
      <!-- <div class="mx-auto px-4 sm:px-6 lg:px-8">
        <div class="pt-0 grid grid-cols-1 gap-1 items-start">
          <div class="grid grid-cols-1 gap-1 lg:col-span-2">
              <span
                class="text-sm font-bold leading-7 text-gray-900 sm:truncate"
              >
                Current Timezone: {{ timezone }}
              </span>
          </div>
        </div>
      </div> -->
      <AppReverseLoginAs />
      <AppGlobalError />
      <h1 id="primary-heading" class="sr-only">Home</h1>
      <router-view v-slot="{ Component, route }">
        <component :is="Component" :key="route.path" />
      </router-view>
    </section>
  </main>
</template>

<script>
import AppGlobalError from "@/views/AppGlobalError.vue";
import AppReverseLoginAs from "@/views/AppReverseLoginAs.vue";
import { getCurrentTimezone } from "@/utils/utils";

export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  components: { AppGlobalError, AppReverseLoginAs },
  data() {
    return {
      timezone: getCurrentTimezone(),
    };
  },
};
</script>

<style lang="scss" scoped></style>
