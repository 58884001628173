import { createRouter, createWebHistory } from "vue-router";
import AppHome from "@/views/home/AppHome.vue";
import store from "@/store";
import StorageUtil from "@/utils/LocalStorageUtil.js";

const routes = [
  {
    path: "/",
    name: "Home",
    component: AppHome,
    meta: { requireAuth: true, title: "Live Calls" },
    redirect: "/live",
    children: [
      {
        path: "/live",
        name: "LiveCall",
        meta: { title: "Live Call" },
        component: () => import("../views/livecall/LiveCall.vue"),
      },
      {
        path: "/recording",
        name: "Recordings",
        meta: { title: "Recordings" },
        component: () => import("../modules/recordings/AppRecordings.vue"),
      },
      {
        path: "/session/:id",
        name: "Session",
        meta: { title: "Session" },
        component: () => import("../modules/recordings/AppSessions.vue"),
      },
      {
        path: "/users",
        name: "Users",
        meta: { title: "Users" },
        component: () => import("../modules/users/AppUsers.vue"),
      },
      {
        path: "/user/:id",
        name: "EditUser",
        meta: { title: "Edit User" },
        component: () => import("../modules/users/EditUser.vue"),
      },
      {
        path: "/users/add",
        name: "AddUser",
        meta: { title: "Add New User" },
        component: () => import("../modules/users/AddUser.vue"),
      },
      {
        path: "/inbounds",
        name: "Inbounds",
        meta: { title: "Inbounds" },
        component: () => import("../modules/inbounds/AppInbounds.vue"),
      },
      {
        path: "/contacts",
        name: "Contacts",
        meta: { title: "Contacts" },
        component: () => import("../modules/contacts/AppContacts.vue"),
      },
      {
        path: "/profile",
        name: "Profile",
        meta: { title: "Profile" },
        component: () => import("../modules/users/AppProfile.vue"),
      },
      {
        path: "/greetings",
        name: "Greetings",
        meta: { title: "Greetings" },
        component: () => import("../modules/greetings/AppGreetings.vue"),
      },
      {
        path: "/voicemail",
        name: "Voice Mail",
        meta: { title: "Voice Mail" },
        component: () => import("../modules/voicemail/AppVoicemail.vue"),
      },
      {
        path: "/sms",
        name: "SMS",
        meta: { title: "SMS" },
        props: { outreach: 0 },
        component: () => import("../modules/sms/AppSMS.vue"),
      },
      {
        path: "/outreach",
        name: "Outreach",
        meta: { title: "Outreach" },
        props: { outreach: 1 },
        component: () => import("../modules/sms/AppSMS.vue"),
      },
      {
        path: "/queues",
        name: "Queues",
        meta: { title: "Queues" },
        component: () => import("../modules/queues/AppQueues.vue"),
      },
      {
        path: "/queues/:id",
        name: "Queue Calls",
        meta: { title: "Queue Calls" },
        component: () => import("../modules/queues/QueueCalls.vue"),
      },
      // {
      //   path: "/:catchAll(.*)",
      //   name: "PageNotFound",
      //   meta: { title: "Not Found" },
      //   component: () => import("../views/PageNotFound.vue"),
      // },
    ],
  },
  {
    path: "/login",
    name: "Login",
    meta: { noRequireAuth: true },
    component: () => import("../views/AppLogin.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const ALLOWED_PATHS = new Set([
  "Home",
  "LiveCall",
  "Recordings",
  "Session",
  "SMS",
  "Login",
  "PageNotFound",
  "Users",
  "EditUser",
  "AddUser",
  "Inbounds",
  "Contacts",
  "Outreach",
  "Devices",
  "Greetings",
  "Queues",
  "Queue Calls",
  "Voice Mail",
  "Profile",
]);

const LO_ALLOWED_PATHS = new Set(["LiveCall", "Profile"]);

router.beforeEach((to, from, next) => {
  store.commit("SET_ROUTE", to.name);
  console.log(from.path, from.name, to.path, to.name);
  const loggedIn = StorageUtil.getUserAuthData();

  if (["SMS", "Outreach"].includes(from.name))
    store.dispatch("removeErrors", { errorType: "sms" }, { root: true });

  if (to.matched.some((route) => route.meta.requireAuth) && !loggedIn) {
    next("/login");
  } else if (to.matched.some((route) => route.meta.noRequireAuth) && loggedIn) {
    next("/");
  } else {
    const userType = StorageUtil.getUserType();
    if (userType === "listen_only" && !LO_ALLOWED_PATHS.has(to.name)) {
      next("/live");
    } else if (
      userType === "conference_manager" &&
      to.name !== "LiveCall" &&
      to.name !== "Recordings" &&
      to.name !== "Inbounds" &&
      to.name !== "Contacts" &&
      to.name !== "Voice Mail" &&
      to.name !== "Session" &&
      to.name !== "SMS" &&
      to.name !== "Devices" &&
      to.name !== "Greetings" &&
      to.name !== "Outreach" &&
      to.name !== "Queues" &&
      to.name !== "Voice Mail" &&
      to.name !== "Queue Calls" &&
      to.name !== "Profile"
    ) {
      console.log(userType, to.name);
      next("/live");
    } else {
      if (ALLOWED_PATHS.has(to.name)) {
        next();
      } else {
        next("/");
      }
    }
  }
});

export default router;
