export const namespaced = true;

export const state = {
  phoneNumberPopup: null
};

export const mutations = {
  SET_PHONE_NUMBER(state, number) {
    state.phoneNumberPopup = number;
  }
};

export const actions = {
  setPhoneNumber({ commit }, number) {
    commit("SET_PHONE_NUMBER", number);
  }
};

export const getters = {
  phoneNumberPopup: (state) => state.phoneNumberPopup
};
