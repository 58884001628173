import StorageUtil from "@/utils/LocalStorageUtil.js";

export function getDatetime(date) {
  const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const userTimezone = StorageUtil.getUserTimezone();
  const targetTimezone =
    userTimezone === undefined || userTimezone === "0" || userTimezone === ""
      ? currentTimezone
      : userTimezone;

  return new Date(date.toLocaleString("en-US", { timeZone: targetTimezone }))
    .toISOString()
    .replace("T", " ")
    .replace("Z", "");
}
export function getCurrentTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function listTimezones() {
  return Intl.supportedValuesOf("timeZone");
}