<template>
  <div class="cc-sms-pop fixed bg-gray-800">
    <h3 class="popup-title" @click="toggleSMSPopup">Send <span>SMS</span></h3>
    <MailIcon class="open-popup" v-if="!isOpened" />
    <XIcon class="close-popup" @click="toggleSMSPopup" v-if="isOpened" />
    <form
      class="transition-all duration-700"
      method="post"
      v-bind:style="{ maxHeight: isOpened ? '900px' : 0 }"
      @submit="sendSMS"
    >
      <div v-if="errors.length !== 0" class="bg-red-50 p-4">
        <div class="flex">
          <div class="flex-shrink-0">
            <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
          <div class="ml-3">
            <h3 class="text-sm font-medium text-red-800">
              There were errors with your submission
            </h3>
            <div class="mt-2 text-sm text-red-700">
              <ul class="list-disc pl-5 space-y-1">
                <li v-for="error in errors" :key="error">
                  {{ error }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div v-if="success" class="rounded-md bg-green-500 p-4">
        <div class="flex">
          <div class="flex-shrink-0">
            <CheckCircleIcon
              class="h-5 w-5 text-green-400"
              aria-hidden="true"
            />
          </div>
          <div class="ml-3">
            <h3 class="text-sm font-medium text-green-800">
              {{ success }}
            </h3>
          </div>
        </div>
      </div>

      <br v-if="success || errors.length !== 0" />

      <div>
        <select
          v-model="didNumber"
          id="did"
          class="
            mt-1
            block
            w-full
            pl-3
            pr-10
            py-2
            text-base
            border-gray-300
            focus:outline-none focus:ring-indigo-500 focus:border-indigo-500
            sm:text-sm
            rounded-md
          "
        >
          <option class="bg-gray-800" value="0" disabled selected>
            SELECT DID Number
          </option>
          <option
            class="bg-gray-800"
            v-for="did in DIDs"
            :key="did.did_number"
            :value="did.did_number"
          >
            {{ did.did_number }} ({{ did.mailbox_name }})
          </option>
        </select>
      </div>
      <div>
        <input
          v-model="phoneNumber"
          placeholder="Phone number"
          type="text"
          class="
            block
            w-full
            shadow-sm
            sm:text-sm
            focus:ring-indigo-500 focus:border-indigo-500
            border-gray-300
            rounded-md
          "
        />
      </div>
      <div>
        <textarea
          v-model="message"
          rows="4"
          placeholder="Message"
          class="
            block
            w-full
            shadow-sm
            sm:text-sm
            focus:ring-indigo-500 focus:border-indigo-500
            border border-gray-300
            rounded-md
          "
        />
      </div>
      <div class="flex-shrink-0 px-4 py-4 flex justify-end">
        <button
          :disabled="loading"
          type="submit"
          class="
            ml-4
            inline-flex
            justify-center
            py-2
            px-4
            border border-transparent
            shadow-sm
            text-sm
            font-medium
            rounded-md
            text-white
            bg-indigo-600
            hover:bg-indigo-700
            focus:outline-none
          "
        >
          {{ loading ? "Sending.." : "Send" }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import {
  CheckCircleIcon,
  MailIcon,
  XCircleIcon,
  XIcon,
} from "@heroicons/vue/solid";
import smsApi from "@/network/sms";
import { mapGetters } from "vuex";

export default {
  components: {
    MailIcon,
    XIcon,
    CheckCircleIcon,
    XCircleIcon,
  },
  data() {
    return {
      isOpened: false,
      loading: false,
      errors: [],
      success: null,

      didNumber: "0",
      phoneNumber: null,
      message: null,
    };
  },
  methods: {
    toggleSMSPopup() {
      this.isOpened = !this.isOpened;
    },

    reset() {
      this.loading = false;
      this.errors = [];
      this.success = null;

      this.didNumber = "0";
      this.phoneNumber = "";
      this.message = "";
    },

    async sendSMS(e) {
      e.preventDefault();
      if (!this.validate()) return;

      this.loading = true;
      try {
        await smsApi.sendSMS(this.didNumber, this.phoneNumber, this.message);
        this.reset();
        this.success = "SMS sent successfully";
        setTimeout(this.toggleSMSPopup, 700);
      } catch (error) {
        if (error.response) {
          this.errors.push(error.response.data["message"]);
        } else {
          this.errors.push("Error while trying to send SMS");
        }
      } finally {
        this.loading = false;
      }
    },

    validate() {
      this.errors = [];
      this.success = null;

      let isValid = true;
      if (this.didNumber === "0") {
        isValid = false;
        this.errors.push("Choose a DID number");
      }

      if (this.phoneNumber === "") {
        isValid = false;
        this.errors.push("Enter a valid phone number");
      }

      if (this.message === "") {
        isValid = false;
        this.errors.push("Enter a message");
      }

      return isValid;
    },
  },
  computed: {
    ...mapGetters("activeCalls", ["DIDs"]),
  },
};
</script>

<style>
.cc-sms-pop {
  color: #fff;
  width: 350px;
  bottom: 0;
  right: 50px;
  border-radius: 6px 6px 0 0;
}

.cc-sms-pop .popup-title {
  border-radius: 6px 6px 0 0;
  text-align: left;
  color: #fff;
  text-shadow: none;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  padding: 15px;
  cursor: pointer;
}

.cc-sms-pop .popup-title span {
  font-style: oblique;
}

.cc-sms-pop .open-popup,
.cc-sms-pop .close-popup {
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  font-weight: 500;
  font-size: 29px;
  padding: 15px;
  cursor: pointer;
  line-height: 24px;
  width: 54px;
  height: 54px;
}

.cc-sms-pop input,
.cc-sms-pop select {
  height: 50px;
  padding: 0 15px;
}

.cc-sms-pop textarea {
  padding: 15px;
}

.cc-sms-pop input,
.cc-sms-pop textarea,
.cc-sms-pop select {
  font-family: "PT Sans", sans-serif;
  font-size: 0.875em;
  width: 100%;
  background: transparent;
  outline: none;
  color: #fff;
  border: solid 1px #eb6166;
  border-bottom: none;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
}
</style>
