<template>
  <div class="min-h-screen overflow-hidden bg-gray-100 flex flex-col">
    <top-nav :title="title"></top-nav>
    <bottom-section :title="title"></bottom-section>
  </div>
  <SendSMS v-if="title !== 'SMS' && title !== 'Outreach'" />
</template>

<script>
import TopNav from "./TopNav.vue";
import BottomSection from "./BottomSection.vue";
import SendSMS from "@/modules/sms/SendSMS.vue";
import { mapActions } from "vuex";
export default {
  components: {
    TopNav,
    BottomSection,
    SendSMS,
  },
  created() {
    this.listDIDs(false);
  },
  methods: {
    ...mapActions("activeCalls", ["listDIDs"]),
  },
  data() {
    return {
      title: this.$route?.meta?.title || "Internal Calls",
    };
  },
};
</script>
