import axiosInstance, { LOGIN_URL, LOGINAS_URL } from "./config.js";

export default {
  async login(email, password) {
    console.log("LOGIN", email, "*".repeat(password.length));

    const params = JSON.stringify({
      username: email,
      password: password,
    });
    const response = await axiosInstance.post(LOGIN_URL, params);
    return response;
  },

  loadProfile() {
    console.log("LOAD_PROFILE");
    // return axiosInstance.get(PROFILE_URL);
  },

  async loginAs(userId) {
    console.log("LOGIN_AS", userId);

    const params = JSON.stringify({
      username: userId,
    });
    const response = await axiosInstance.post(LOGINAS_URL, params);
    return response;
  },
};
