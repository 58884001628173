<template>
  <div class="flex">
    <div class="relative inline-flex shadow-sm rounded-md">
      <Menu as="span" class="relative block" v-if="this.connected">
        <MenuButton
          :disabled="isLoading"
          class="h-11 relative inline-flex items-center px-2 py-2 rounded-l-md text-sm font-medium text-white bg-gray-800 hover:bg-gray-700 focus:outline-none"
        >
          <span class="sr-only">Open options</span>
          <AdjustmentsIcon class="h-5 w-5" aria-hidden="true" />
        </MenuButton>
        <transition
          enter-active-class="transition ease-out duration-100"
          enter-from-class="transform opacity-0 scale-95"
          enter-to-class="transform opacity-100 scale-100"
          leave-active-class="transition ease-in duration-75"
          leave-from-class="transform opacity-100 scale-100"
          leave-to-class="transform opacity-0 scale-95"
        >
          <MenuItems
            class="z-50 origin-top-right absolute right-0 mt-2 -mr-1 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            <div class="p-4">
              <div class="flex items-center space-x-3 pr-2">
                <button
                  @click="toggleSpeaker"
                  title="Mute speaker"
                  type="button"
                  class="inline-flex items-center px-3 py-2 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none"
                >
                  <VolumeUpIcon
                    v-if="!speakerMuted"
                    class="h-4 w-4"
                    aria-hidden="true"
                  />
                  <VolumeOffIcon
                    v-if="speakerMuted"
                    class="h-4 w-4"
                    aria-hidden="true"
                  />
                </button>
                <Slider
                  @change="speakerVolumeUpdated"
                  :tooltips="false"
                  class="w-full"
                  v-model="speakerVolumeComputed"
                />
              </div>
              <div class="mt-2">
                <select
                  :disabled="!this.connected"
                  @change="speakerChanged"
                  v-model="speaker"
                  class="w-full h-11 block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none sm:text-sm rounded-md"
                >
                  <option
                    v-for="speakerItem in speakers"
                    :key="speakerItem.groupId"
                    :value="speakerItem"
                  >
                    {{ speakerItem.label }}
                  </option>
                </select>
              </div>

              <div class="w-full h-px bg-gray-100 my-3"></div>

              <div class="flex items-center space-x-3 pr-2">
                <button
                  @click="toggleMicrophone"
                  title="Mute microphone"
                  type="button"
                  class="inline-flex items-center px-3 py-2 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none"
                >
                  <StatusOnlineIcon
                    v-if="!microphoneMuted"
                    class="h-4 w-4"
                    aria-hidden="true"
                  />
                  <StatusOfflineIcon
                    v-if="microphoneMuted"
                    class="h-4 w-4"
                    aria-hidden="true"
                  />
                </button>
                <Slider
                  @change="micVolumeUpdated"
                  :tooltips="false"
                  class="w-full"
                  v-model="micVolumeComputed"
                />
              </div>
              <div class="mt-2">
                <select
                  :disabled="!this.connected"
                  @change="micChanged"
                  v-model="microphone"
                  class="w-full h-11 block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none sm:text-sm rounded-md"
                >
                  <option
                    v-for="micItem in microphones"
                    :key="micItem.groupId"
                    :value="micItem"
                  >
                    {{ micItem.label }}
                  </option>
                </select>
              </div>
            </div>
          </MenuItems>
        </transition>
      </Menu>
      <button
        @click="connect"
        type="button"
        class="relative inline-flex items-center px-4 py-2 shadow-sm text-sm font-medium text-white bg-gray-800 hover:bg-gray-700 focus:outline-none"
      >
        <LogoutIcon
          v-if="!this.connected"
          class="mr-1 h-5 w-5"
          aria-hidden="true"
        />
        <LoginIcon
          v-if="this.connected"
          class="mr-1 h-5 w-5"
          aria-hidden="true"
        />
        {{ !this.connected ? "Connect" : "Disconnect" }}
        ({{
          selectedRoom?.conference_name ??
          (rooms[0] ? rooms[0]?.conference_name : "")
        }})
        <div class="ml-3">
          <span
            v-if="this.connected"
            class="animate-ping absolute right-3 top-2.5 inline-flex h-4 w-4 rounded-full bg-green-500 opacity-75"
          ></span>
          <span
            :class="[
              this.connected ? 'bg-green-500' : 'bg-red-500',
              'block h-2 w-2 rounded-full ring-2 ring-whit',
            ]"
          />
        </div>
      </button>
      <button
        class="relative block"
        v-if="isLoading || this.connected"
        @click="connect"
      >
        <span
          class="h-11 relative inline-flex items-center px-2 py-2 rounded-r-md text-sm font-medium text-white bg-gray-800 hover:bg-gray-700 focus:outline-none"
        >
          <StopIcon class="h-5 w-5" aria-hidden="true" />
        </span>
      </button>
      <Menu
        as="span"
        class="relative block"
        v-if="!isLoading && !this.connected"
      >
        <MenuButton
          :disabled="isLoading || this.connected"
          class="h-11 relative inline-flex items-center px-2 py-2 rounded-r-md text-sm font-medium text-white bg-gray-800 hover:bg-gray-700 focus:outline-none"
        >
          <span class="sr-only">Open options</span>
          <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
        </MenuButton>
        <transition
          enter-active-class="transition ease-out duration-100"
          enter-from-class="transform opacity-0 scale-95"
          enter-to-class="transform opacity-100 scale-100"
          leave-active-class="transition ease-in duration-75"
          leave-from-class="transform opacity-100 scale-100"
          leave-to-class="transform opacity-0 scale-95"
        >
          <MenuItems
            class="z-50 origin-top-right absolute right-0 mt-2 -mr-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            <div class="py-1">
              <MenuItem
                v-for="room in rooms"
                :key="room.conference_name"
                v-slot="{ active }"
                @click="selectRoom({ room })"
              >
                <a
                  href="#"
                  :class="[
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm',
                  ]"
                >
                  {{ room.conference_name }}
                </a>
              </MenuItem>
            </div>
          </MenuItems>
        </transition>
      </Menu>
    </div>

    <audio ref="remoteAudio" hidden controls class="hidden">
      <p>Your browser doesn't support HTML5 audio.</p>
    </audio>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { LogoutIcon, LoginIcon, AdjustmentsIcon } from "@heroicons/vue/outline";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import StorageUtil from "@/utils/LocalStorageUtil.js";
import Slider from "@vueform/slider";

import {
  ChevronDownIcon,
  VolumeUpIcon,
  VolumeOffIcon,
  StatusOnlineIcon,
  StatusOfflineIcon,
  StopIcon,
} from "@heroicons/vue/solid";

export default {
  components: {
    AdjustmentsIcon,
    LogoutIcon,
    LoginIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
    VolumeUpIcon,
    VolumeOffIcon,
    StatusOnlineIcon,
    StatusOfflineIcon,
    StopIcon,
    Slider,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  async created() {
    window.addEventListener("beforeunload", (event) => {
      if (this.connected) {
        event.preventDefault();
        event.returnValue = "";
      }
    });
  },
  mounted() {
    this.setRemoteAudio(this.$refs.remoteAudio);
    window.addEventListener("unload", this.disconnect);
  },
  beforeUnmount() {
    window.removeEventListener("unload", this.disconnect);
  },
  methods: {
    async connect() {
      if (this.connected) {
        this.hangup();
      } else {
        await this.makeWebRtcCall();
      }
    },
    async disconnect() {
      this.phone.stop();
      this.$refs.remoteAudio.srcObject = null;
      this.$refs.remoteAudio.pause();
    },
    async makeWebRtcCall() {
      const destination =
        this.selectedRoom?.conference_name ??
        (this.rooms[0] ? this.rooms[0]?.conference_name : "");
      const fromName = this.fullName;
      this.makeCall({ fromName, destination });
    },
    roomSelected(room) {
      this.selectedRoom.conference_name = room.conference_name;
    },
    toggleSpeaker() {
      const receivers = this.session.connection.getReceivers();

      this.$refs.remoteAudio.muted = !this.speakerMuted;
      this.setSpeakerMuted(!this.speakerMuted);
      if (this.speakerMuted) {
        this.speakerVolumeComputed = parseFloat(0);
        this.$refs.remoteAudio.volume = parseFloat(0);

        receivers.forEach((receiver) => {
          receiver.track.enabled = false;
        });
      } else {
        this.speakerVolumeComputed = parseFloat(100);
        this.$refs.remoteAudio.volume = parseFloat(1);

        receivers.forEach((receiver) => {
          receiver.track.enabled = true;
        });
      }
    },
    toggleMicrophone() {
      this.setMicrophoneMuted(!this.microphoneMuted);
      if (this.microphoneMuted) {
        this.micVolumeComputed = 0;
        this.session.mute({ audio: true });
      } else {
        this.micVolumeComputed = 100;
        this.session.unmute({ audio: true });
      }
    },
    speakerVolumeUpdated(value) {
      console.log("speakerVolumeUpdated", value);
      const volume = parseFloat(value / 100);
      this.setSpeakerMuted(value == 0);
      this.setSpeakerVolume(value);
      this.$refs.remoteAudio.volume = volume;
      if (value == 0 && !this.$refs.remoteAudio.muted)
        this.$refs.remoteAudio.muted = true;
      if (value !== 0 && this.$refs.remoteAudio.muted)
        this.$refs.remoteAudio.muted = false;
    },

    micVolumeUpdated(value) {
      console.log("micVolumeUpdated", value);
      this.setMicrophoneMuted(value == 0);
      this.setMicrophoneVolume(value);

      navigator.mediaDevices
        .getUserMedia({
          audio: {
            deviceId: {
              exact: this.selectedMicrophone.deviceId,
            },
          },
        })
        .then((stream) => {
          stream = this.modifyGain(stream, this.micVolumeComputed / 100);
          const [audioTrack] = stream.getAudioTracks();
          this.session.connection
            .getSenders()
            .filter(function (sender) {
              return sender.track && sender.track.kind === "audio";
            })
            .forEach((sender) => {
              sender.replaceTrack(audioTrack);
            });
        })
        .catch((err) => {
          console.error(`Error happened: ${err}`);
        });

      if (value == 0 && !this.session.isMuted().audio)
        this.session.mute({ audio: true });
      if (value !== 0 && this.session.isMuted().audio)
        this.session.unmute({ audio: true });
    },
    speakerChanged() {
      this.$refs.remoteAudio.setSinkId(this.selectedSpeaker.deviceId);
    },
    modifyGain(stream, gainValue) {
      var ctx = new AudioContext();
      var src = ctx.createMediaStreamSource(stream);
      var dst = ctx.createMediaStreamDestination();
      var gainNode = ctx.createGain();
      gainNode.gain.value = gainValue;
      [src, gainNode, dst].reduce((a, b) => a && a.connect(b));
      return dst.stream;
    },
    micChanged() {
      navigator.mediaDevices
        .getUserMedia({
          audio: {
            deviceId: {
              exact: this.selectedMicrophone.deviceId,
            },
          },
        })
        .then((stream) => {
          stream = this.modifyGain(stream, this.micVolumeComputed / 100);
          const [audioTrack] = stream.getAudioTracks();
          this.session.connection
            .getSenders()
            .filter(function (sender) {
              return sender.track && sender.track.kind === "audio";
            })
            .forEach((sender) => {
              sender.replaceTrack(audioTrack);
            });
        })
        .catch((err) => {
          console.error(`Error happened: ${err}`);
        });
    },
    ...mapActions("webrtc", [
      "loadDevices",
      "selectRoom",
      "selectSpeaker",
      "selectMicrophone",
      "makeCall",
      "hangup",
      "setSelectedRoom",
      "setSpeakerVolume",
      "setSpeakerMuted",
      "setMicrophoneVolume",
      "setMicrophoneMuted",
      "setConnected",
      "setRemoteAudio",
    ]),
  },
  computed: {
    fullName() {
      return StorageUtil.getUserData()?.fullName;
    },
    speaker: {
      get: function () {
        return this.selectedSpeaker;
      },
      set: function (value) {
        this.selectSpeaker({ speaker: value });
      },
    },
    microphone: {
      get: function () {
        return this.selectedMicrophone;
      },
      set: function (value) {
        this.selectMicrophone({ microphone: value });
      },
    },
    speakerVolumeComputed: {
      get: function () {
        return this.speakerVolume;
      },
      set: function (value) {
        this.setSpeakerVolume(value);
      },
    },
    micVolumeComputed: {
      get: function () {
        return this.micVolume;
      },
      set: function (value) {
        this.setMicrophoneVolume(value);
      },
    },
    ...mapGetters("rooms", ["rooms"]),
    ...mapGetters("webrtc", [
      "speakers",
      "microphones",

      "selectedSpeaker",
      "selectedMicrophone",

      "speakerMuted",
      "microphoneMuted",

      "speakerVolume",
      "micVolume",

      "selectedRoom",
      "loading",
      "connected",

      "session",
      "phone",
    ]),
  },
};
</script>

<style src="@vueform/slider/themes/default.css"></style>
