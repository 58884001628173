import smsApi from "@/network/sms";
export const namespaced = true;

export const state = {
  search: "",
  number: "",
  from: "",
  to: "",
  page: 1,
  totals: 0,

  dtmfNumber: null,
  DIDs: [],
  active_calls: [],

  loading: false,
  error: null,
};

export const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading;
  },

  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_DIDS(state, DIDs) {
    state.DIDs = DIDs;
  },
  APPEND_CALL(state, call) {
    state.active_calls.unshift(call);
  },
  REMOVE_CALL(state, uniqueId) {
    state.active_calls = state.active_calls.filter(
      (call) => call.uniqueId !== uniqueId
    );
  },
  SET_DTMF_NUM(state, dtmf) {
    state.dtmfNumber = dtmf;
  },
};

export const actions = {
  async listDIDs({ commit }, outreach) {
    const { data } = await smsApi.listDids({ outreach });
    const DIDs = data?.numbers.map((number) => ({
      did_number: number.number,
      mailbox_name: number.number,
      outreach: number.outreach,
      is_deleted: false,
    }));
    commit("SET_DIDS", DIDs);
  },
  appendCall({ commit }, call) {
    commit("APPEND_CALL", call);
  },
  removeCall({ commit }, uniqueId) {
    commit("REMOVE_CALL", uniqueId);
  },
  updatePage({ commit }, { page }) {
    commit("SET_PAGE", page);
  },
  setFilterValues({ commit }, { term, number, from, to }) {
    commit("SET_PAGE", 1);
    commit("SET_SEARCH", { term, number });
    commit("SET_DATE_RANGE", { from, to });
  },
  setDTMF({ commit }, { callId }) {
    commit("SET_DTMF_NUM", callId);
  },
  async sendDTMF({ state, commit, dispatch }, { dtmf }) {
    commit("SET_LOADING", true);
    commit("SET_ERROR", null);

    try {
      const request = {
        request: "dtmf",
        uniqueId: state.dtmfNumber,
        digits: dtmf,
      };

      dispatch("sendMessage", JSON.stringify(request), { root: true });
    } catch (error) {
      commit(
        "SET_ERROR",
        error?.response?.data?.message ?? "Something went wrong"
      );
    } finally {
      commit("SET_LOADING", false);
    }
  },
  async wsListeners({ dispatch, rootState }) {
    rootState.ws.addEventListener("message", (msg) => {
      const message = JSON.parse(msg.data);
      switch (message.action) {
        case "add-member": {
          const callerIdNumber = message.callerIdNumber
            ? message.callerIdNumber
            : "UNKNOWN";

          const call = {
            calledNumber: message.calledNumber,
            callerIdNumber: callerIdNumber,
            callerIdName: message.callerIdName,
            endedAt: null,
            sessionId: message.sessionId,
            startedAt: Math.round(new Date().getTime() / 1000),
            status: "active",
            uniqueId: message.uniqueId,
            memberId: message.memberId,
            roomId: message.roomId,
          };
          dispatch("appendCall", call);
          break;
        }
        case "del-member": {
          dispatch("removeCall", message.uniqueId);

          const request = {
            request: "calls",
            action: "recent-calls",
            limit: 1000,
          };
          dispatch("sendMessage", JSON.stringify(request), { root: true });
          break;
        }
      }
    });
  },
};

export const getters = {
  DIDs: (state) => state.DIDs,
  active_calls: (state) => state.active_calls,

  target_calls: (state) => {
    const CALLS_PER_PAGE = 10;
    const lowerLimit = (state.page - 1) * CALLS_PER_PAGE;
    const upperLimit =
      lowerLimit + CALLS_PER_PAGE > state.active_calls.length
        ? state.active_calls.length
        : lowerLimit + CALLS_PER_PAGE;
    return state.active_calls.slice(lowerLimit, upperLimit);
  },

  dtmfNumber: (state) => state.dtmfNumber,
  search: (state) => state.search,
  page: (state) => state.page,
  totals: (state) => state.active_calls.length,
};
