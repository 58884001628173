// import voicemailApi from "@/network/voicemail.js";

export const namespaced = true;

export const state = {
  voicemails: [],
  raw_voicemails: [],
  page: 1,
  search: "",
  duration: -1,
  from: "",
  to: "",
  totals: 0,
  loading: false,
  error: null,
};

export const mutations = {
  SET_VOICE_MAILS(state, voicemails) {
    state.voicemails = voicemails;
  },

  SET_RAW_VOICE_MAILS(state, voicemails) {
    state.raw_voicemails = voicemails;
  },

  REMOVE_VOICE_MAIL(state, id) {
    state.voicemails = state.voicemails.filter((vm) => vm.recordingId !== id);
  },

  ADD_VOICE_MAIL(state, voicemail) {
    state.voicemails.unshift(voicemail);
  },

  SET_SEARCH(state, { term, duration }) {
    state.search = term;
    state.duration = duration;
  },

  SET_DATE_RANGE(state, { from, to }) {
    state.from = from;
    state.to = to;
  },

  SET_PAGE(state, value) {
    state.page = value;
  },

  SET_TOTALS(state, value) {
    state.totals = value;
  },

  SET_LOADING(state, loading) {
    state.loading = loading;
  },

  SET_ERROR(state, error) {
    state.error = error;
  },
};

export const actions = {
  setVoicemails({ commit, state }, voicemails) {
    commit("SET_RAW_VOICE_MAILS", voicemails);
    if (state.from !== "" && state.to !== "") {
      const fromDate = new Date(Date.parse(state.from + " UTC"));
      const toDate = new Date(Date.parse(state.to + " UTC"));
      voicemails = voicemails.filter(
        (vm) =>
          fromDate <=
            new Date(Date.parse(new Date(vm.origTime) + " UTC")) &&
          new Date(Date.parse(new Date(vm.origTime) + " UTC")) <= toDate
      );
    }

    if (state.search && state.search !== "") {
      voicemails = voicemails.filter(
        (vm) =>
          vm.callerid?.includes(state.search) ||
          vm.number?.includes(state.search)
      );
    }

    if (state.duration && state.duration !== "") {
      voicemails = voicemails.filter((vm) => vm.duration >= state.duration);
    }

    commit("SET_VOICE_MAILS", voicemails);
    commit("SET_TOTALS", voicemails.length);
    commit("SET_LOADING", false);
  },
  setFilterValues({ commit }, { term, duration, from, to }) {
    commit("SET_PAGE", 1);
    commit("SET_SEARCH", { term, duration });
    commit("SET_DATE_RANGE", { from, to });
  },
  async listVoicemails({ commit, dispatch }, payload) {
    commit("SET_LOADING", true);
    const defaultLimit = 100;
    const vmLimit = payload?.limit ? payload?.limit : defaultLimit;

    const request = {
      request: "mailboxes",
      action: "list",
      limit: vmLimit,
    };
    dispatch("sendMessage", JSON.stringify(request), { root: true });
  },

  removeVoicemail({ commit, dispatch }, { recordingId }) {
    commit("SET_LOADING", true);

    const request = {
      request: "mailboxes",
      action: "remove",
      recordingId: recordingId,
    };

    dispatch("sendMessage", JSON.stringify(request), { root: true });
  },

  updatePage({ commit }, { page }) {
    commit("SET_PAGE", page);
  },

  async wsListeners({ commit, rootState, dispatch }) {
    rootState.ws.addEventListener("message", (msg) => {
      const message = JSON.parse(msg.data);
      switch (message.action) {
        case "list-voicemail": {
          dispatch("setVoicemails", message.voicemails);
          break;
        }
        case "remove-voicemail": {
          commit("REMOVE_VOICE_MAIL", message.voicemail?.recordingId);
          commit("SET_LOADING", false);
          break;
        }
      }
    });
  },
};

export const getters = {
  voicemails: (state) => state.voicemails,

  target_voicemails: (state) => {
    const VMS_PER_PAGE = 10;
    const lowerLimit = (state.page - 1) * VMS_PER_PAGE;
    const upperLimit =
      lowerLimit + VMS_PER_PAGE > state.voicemails.length
        ? state.voicemails.length
        : lowerLimit + VMS_PER_PAGE;
    return state.voicemails.slice(lowerLimit, upperLimit);
  },

  page: (state) => state.page,
  totals: (state) => state.totals,
  loading: (state) => state.loading,
  error: (state) => state.error,
};
