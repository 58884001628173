<template>
  <header class="flex-shrink-0 relative h-16 bg-white flex items-center">
    <logo-area></logo-area>
    <top-bar :title="title"></top-bar>
  </header>
</template>

<script>
import LogoArea from "./LogoArea.vue";
import TopBar from "./TopBar.vue";

export default {
  components: {
    LogoArea,
    TopBar,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped></style>
