export const namespaced = true;

export const state = {
  queues: [],
  queue: null,
  current_queueId: null,
  page: 1,
  totals: 0,
  loading: false,
  error: null,
};

export const mutations = {
  SET_QUEUES(state, queues) {
    state.queues = queues;
  },

  ADD_QUEUE(state, queue) {
    state.queues.push(queue);
  },

  UPDATE_QUEUE(state, queue) {
    const queueIndex = state.queues.findIndex((item) => item.id === queue.id);
    if (queueIndex === -1) return;
    state.queues.splice(
      queueIndex,
      1,
      Object.assign(state.queues[queueIndex], { name: queue.name })
    );
  },

  REMOVE_QUEUE(state, id) {
    const queueIndex = state.queues.findIndex((queue) => queue.id === id);
    if (queueIndex === -1) return;
    state.queues.splice(queueIndex, 1);
  },

  SET_PAGE(state, value) {
    state.page = value;
  },

  SET_TOTALS(state, value) {
    state.totals = value;
  },

  SET_LOADING(state, loading) {
    state.loading = loading;
  },

  SET_ERROR(state, error) {
    state.error = error;
  },

  SET_QUEUE(state, queue) {
    state.queue = queue;
    state.current_queueId = queue.id;
  },
  SET_CURRENT_QUEUE_ID(state, queueId) {
    state.current_queueId = queueId;
  },
  ADD_QUEUE_CALL(state, { queue, call }) {
    const queueIndex = state.queues.findIndex(
      (queueItem) => queueItem.id === queue.id
    );

    if (queueIndex === -1) return;

    state.queues[queueIndex].calls.push(call);
  },
  UPDATE_QUEUE_CALL(state, { queue, call }) {
    const queueIndex = state.queues.findIndex(
      (queueItem) => queueItem.id === queue.id
    );

    if (queueIndex === -1) return;

    const callIndex = state.queues[queueIndex].calls.findIndex(
      (callItem) => callItem.callId === call.callId
    );

    if (callIndex === -1) return;

    state.queues[queueIndex].calls.splice(callIndex, 1, call);
  },

  UPDATE_QUEUE_CALL_STATUS(state, { queue, call }) {
    const queueIndex = state.queues.findIndex(
      (queueItem) => queueItem.id === queue.id
    );

    if (queueIndex === -1) return;

    const callIndex = state.queues[queueIndex].calls.findIndex(
      (callItem) => callItem.callId === call.callId
    );

    if (callIndex === -1) return;

    state.queues[queueIndex].calls.splice(callIndex, 1, call);
  },

  REMOVE_QUEUE_CALL(state, { queueId, id}) {
    console.log('REMOVE_QUEUE_CALL', queueId, id)
    const queueIndex = state.queues.findIndex(
      (queueItem) => queueItem.id === queueId
    );

    if (queueIndex === -1) return;

    state.queues[queueIndex].calls = state.queues[queueIndex].calls.filter((call) => call.callId !== id);
  },
};

export const actions = {
  updatePage({ commit }, { page }) {
    commit("SET_PAGE", page);
  },
  setCurrentQueueId({ commit }, queueId) {
    commit("SET_CURRENT_QUEUE_ID", queueId);
  },
  async listQueues({ dispatch, commit }, payload) {
    commit("SET_LOADING", true);
    const defaultLimit = 100;
    const queuesLimit = payload?.limit ? payload?.limit : defaultLimit;

    const request = {
      request: "queues",
      action: "list",
      limit: queuesLimit,
    };
    dispatch("sendMessage", JSON.stringify(request), { root: true });
  },
  addQueue({ dispatch, commit }, { name }) {
    commit("SET_LOADING", true);
    const request = { request: "queues", action: "create", name: name };
    dispatch("sendMessage", JSON.stringify(request), { root: true });
  },
  updateQueue({ dispatch, commit }, { name, id }) {
    console.log("updateQueue", id, name);
    commit("SET_LOADING", true);
    const request = {
      request: "queues",
      action: "update",
      id: id,
      name: name,
    };
    dispatch("sendMessage", JSON.stringify(request), { root: true });
  },
  async getQueueDetails({ state, commit }, queueId) {
    commit("SET_LOADING", true);
    const queueIndex = state.queues.findIndex((item) => item.id === queueId);

    if (queueIndex > -1) {
      commit("SET_QUEUE", state.queues[queueIndex]);
    }
    commit("SET_LOADING", false);
    // const request = {
    //   request: "queues",
    //   action: "listCalls",
    //   id: queueId,
    // };
    // console.log("getQueueDetails", JSON.stringify(request));
    // dispatch("sendMessage", JSON.stringify(request), { root: true });
  },
  removeQueue({ dispatch, commit }, { queueId }) {
    commit("SET_LOADING", true);

    const request = { request: "queues", action: "removeQueue", id: queueId };
    dispatch("sendMessage", JSON.stringify(request), { root: true });
  },
  addCall({ dispatch, commit }, { queueId, ...data }) {
    commit("SET_LOADING", true);
    const request = {
      request: "queues",
      action: "addCall",
      id: queueId,
      number: data.number,
      callerIdName: data.callerIdName,
      callerIdNumber: data.callerIdNumber,
      notes: data.notes,
    };
    dispatch("sendMessage", JSON.stringify(request), { root: true });
  },
  updateCall({ dispatch, commit }, { queueId, ...call }) {
    commit("SET_LOADING", true);
    const request = {
      request: "queues",
      action: "updateCall",
      id: queueId,
      call: call,
    };
    dispatch("sendMessage", JSON.stringify(request), { root: true });
  },
  removeCall({ dispatch, commit }, { queueId, callId }) {
    commit("SET_LOADING", true);

    const request = {
      request: "queues",
      action: "removeCall",
      id: queueId,
      callId: callId,
    };
    dispatch("sendMessage", JSON.stringify(request), { root: true });
  },
  updateCallStatus({ commit, dispatch }, { queue, call }) {
    commit("SET_LOADING", true);
    const request = {
      request: "queues",
      action: "updateCall",
      id: queue.id,
      call: call,
    };
    dispatch("sendMessage", JSON.stringify(request), { root: true });
  },
  updateQueueCallStatus({ state, commit, dispatch }, { callID, status }) {
    commit("SET_LOADING", true);
    const call = state.queue.calls.find((call) => call.callId === callID);
    call.status = status;
    const request = {
      request: "queues",
      action: "updateCall",
      id: state.queue.id,
      call: call,
    };
    dispatch("sendMessage", JSON.stringify(request), { root: true });
  },
  async wsListeners({ commit, rootState, state, dispatch }) {
    rootState.ws.addEventListener("message", (msg) => {
      const message = JSON.parse(msg.data);
      switch (message.action) {
        case "list-queues": {
          commit("SET_QUEUES", message.queues);
          commit("SET_TOTALS", message.queues.length);
          if (state.current_queueId)
            dispatch("getQueueDetails", state.current_queueId);
          commit("SET_LOADING", false);
          break;
        }
        case "queue-list-calls": {
          console.log("queue-list-calls", {
            call: message.call,
            queue: message.queue,
          });
          commit("SET_LOADING", false);
          break;
        }
        case "create-queue": {
          commit("ADD_QUEUE", message.queue);
          commit("SET_LOADING", false);
          break;
        }
        case "queue-add-call": {
          commit("ADD_QUEUE_CALL", {
            call: message.call,
            queue: message.queue,
          });
          commit("SET_LOADING", false);
          break;
        }
        case "queue-update-call": {
          commit("UPDATE_QUEUE_CALL", {
            call: message.call,
            queue: message.queue,
          });
          commit("SET_LOADING", false);
          break;
        }
        case "queue-remove-call": {
          console.log("mm",message.call?.callId)
          commit("REMOVE_QUEUE_CALL", {
            queueId: message.queue?.id,
            id: message.call?.callId
          });
          commit("SET_LOADING", false);
          break;
        }
        case "update-queue": {
          commit("UPDATE_QUEUE", message.queue);
          commit("SET_LOADING", false);
          break;
        }
        case "remove-queue": {
          commit("REMOVE_QUEUE", message.queue?.id);
          commit("SET_LOADING", false);
          break;
        }
      }
    });
  },
};

export const getters = {
  queues: (state) => state.queues,
  page: (state) => state.page,
  totals: (state) => state.totals,
  loading: (state) => state.loading ?? true,
  error: (state) => state.error,
  queue: (state) => state.queue ?? null,
};
