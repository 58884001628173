// import inboundsApi from "@/network/inbounds.js";

export const namespaced = true;

export const state = {
  inbounds: [],
  page: 1,
  totals: 0,
  loading: false,
  error: null,
};

export const mutations = {
  SET_INBOUNDS(state, inbounds) {
    state.inbounds = inbounds;
  },

  ADD_INBOUND(state, inbound) {
    state.inbounds.unshift(inbound);
  },

  REMOVE_INBOUND(state, number) {
    state.inbounds = state.inbounds.filter((item) => item.number !== number);
  },

  UPDATE_INBOUND(state, inbound) {
    const index = state.inbounds.findIndex(
      (item) => item.number === inbound.number
    );
    if (state.inbounds[index]) {
      state.inbounds[index] = inbound;
    }
  },

  SET_PAGE(state, value) {
    state.page = value;
  },

  SET_TOTALS(state, value) {
    state.totals = value;
  },

  SET_LOADING(state, loading) {
    state.loading = loading;
  },

  SET_ERROR(state, error) {
    state.error = error;
  },
};

export const actions = {
  async listInbounds({ commit, dispatch }, payload) {
    commit("SET_LOADING", true);
    const defaultLimit = 100;
    const inboundsLimit = payload?.limit ? payload?.limit : defaultLimit;

    const request = {
      request: "numbers",
      action: "list",
      limit: inboundsLimit,
    };
    dispatch("sendMessage", JSON.stringify(request), { root: true });
  },

  addInbound({ commit, dispatch }, { inbound }) {
    commit("SET_LOADING", true);

    const request = {
      request: "numbers",
      action: "create",
      number: inbound.number,
      username: inbound.username,
      description: inbound.description,
      onTimeout: inbound.onTimeout,
      forwardTo: inbound.forwardTo,
      ringTimeout: inbound.ringTimeout,
      outreach: inbound.outreach,
      greetingId: inbound.greetingId,
      pin: inbound.pin,
      smsProvider: inbound.smsProvider,
    };
    dispatch("sendMessage", JSON.stringify(request), { root: true });
  },

  updateInbound({ commit, dispatch }, { inbound }) {
    commit("SET_LOADING", true);

    const request = {
      request: "numbers",
      action: "update",
      number: inbound.number,
      username: inbound.username,
      description: inbound.description,
      onTimeout: inbound.onTimeout,
      forwardTo: inbound.forwardTo,
      ringTimeout: inbound.ringTimeout,
      outreach: inbound.outreach,
      greetingId: inbound.greetingId,
      pin: inbound.pin,
      smsProvider: inbound.smsProvider,
    };
    dispatch("sendMessage", JSON.stringify(request), { root: true });
  },

  removeInbound({ commit, dispatch }, { number }) {
    commit("SET_LOADING", true);

    const request = {
      request: "numbers",
      action: "remove",
      number: number,
    };
    dispatch("sendMessage", JSON.stringify(request), { root: true });
  },

  updatePage({ commit }, { page }) {
    commit("SET_PAGE", page);
  },
  async wsListeners({ commit, rootState }) {
    rootState.ws.addEventListener("message", (msg) => {
      const message = JSON.parse(msg.data);
      switch (message.action) {
        case "list-numbers": {
          commit("SET_INBOUNDS", message.numbers);
          commit("SET_TOTALS", message.numbers.length);
          commit("SET_LOADING", false);
          break;
        }
        case "create-number": {
          commit("ADD_INBOUND", message.number);
          commit("SET_LOADING", false);
          break;
        }
        case "update-number": {
          commit("UPDATE_INBOUND", message.number);
          commit("SET_LOADING", false);
          break;
        }
        case "remove-number": {
          commit("REMOVE_INBOUND", message.number?.number);
          commit("SET_LOADING", false);
          break;
        }
      }
    });
  },
};

export const getters = {
  inbounds: (state) => state.inbounds,

  target_inbounds: (state) => {
    const PER_PAGE = 10;
    const lowerLimit = (state.page - 1) * PER_PAGE;
    const upperLimit =
      lowerLimit + PER_PAGE > state.inbounds.length
        ? state.inbounds.length
        : lowerLimit + PER_PAGE;
    return state.inbounds.slice(lowerLimit, upperLimit);
  },

  page: (state) => state.page,
  totals: (state) => state.totals,
  loading: (state) => state.loading,
  error: (state) => state.error,
};
