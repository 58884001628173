<template>
  <router-view v-slot="{ Component, route }">
    <component :is="Component" :key="route.path" />
  </router-view>
</template>

<script>
import StorageUtil from "@/utils/LocalStorageUtil.js";

export default {
  name: "App",
  async created() {
    const userData = StorageUtil.getUserAuthData();
    const userReverseData = StorageUtil.getUserAuthData(true);

    if (userReverseData) {
      await this.$store.dispatch("setReverseLogin", true);
      await this.$store.commit("auth/SET_REVERSE_AUTH_DATA", userReverseData);
    }

    if (userData) {
      await this.$store.commit("auth/SET_AUTH_DATA", userData);
      await this.$store.dispatch("initWs", userData?.data?.token);
      await this.$store.dispatch("webrtc/initWebRtc", userData?.webrtc);
      await this.$store.dispatch(
        "webrtc/initWebRtcListeners",
        userData?.webrtc
      );
      await this.$store.dispatch("webrtc/loadDevices");

      navigator.mediaDevices.addEventListener("devicechange", async (event) => {
        console.log("MEDIA_DEVICES_CHANGE", event);
        await this.$store.dispatch("webrtc/loadDevices");
      });
    }
  },
};
</script>

<style></style>
